import React, {useEffect, useState} from 'react';
import ReactLoading from "react-loading";
import { Button, Tag, Table, Statistic } from 'antd'
import { getAssignments } from '../api/admin';
import { convertUtcToNow, convertTimeToTimeAgo, limitLength } from '../utils/general';
import { getPresignedUrl } from '../api/s3';


const AssignmentDashboard = (props) => {
  const COLUMNS = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at) => {
      created_at = convertUtcToNow(created_at);
      return convertTimeToTimeAgo(created_at)
    }
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (title) => {
      return limitLength(title, 50)
    }
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (user) => {
      return <span>{limitLength(user.name, 50)} <br /> {limitLength(user.email, 50)}</span>
    }
  },
  {
    title: 'Grade',
    dataIndex: 'grade',
    key: 'grade',
    render: (grade) => {
      return grade
    }
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
    render: (subject) => {
      return subject
    }
  },
  {
    title: 'Assignment Type',
    dataIndex: 'assignment_type',
    key: 'assignment_type',
    render: (assignment_type) => {
      return assignment_type === "Student-Based Assignment" ? <Tag color="blue">SBA</Tag> : <Tag color="orange">READING</Tag>
    }
  },
  {
    title: 'Concepts',
    dataIndex: 'conversation_flow',
    key: 'conversation_flow',
    render: (conversation_flow) => {
      return <span>{conversation_flow.map(question => {
        return question.type === "base" && question.concept ? (
          <p>{question.concept}</p>
        ) : null
      })}</span>
    }
  },
  {
    title: 'File',
    dataIndex: 'file_name',
    key: 'file_name',
    render: (file_name) => {
      return file_name ? <Button onClick={async ()=>{
        window.location.href = await getPresignedUrl(file_name)
      }}>PDF</Button> : null
    }
  },
  {
    title: 'Num Conversations',
    dataIndex: 'assignment_conversations_length',
    key: 'assignment_conversations_length',
    render: (assignment_conversations_length) => {
      return assignment_conversations_length
    }
  },
  
]
  
  const [assignments, setAssignments] = useState(null);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchAssignments = async () => {
        setIsLoading(true)
        getAssignments(props.weeksAgo)
            .then(response => {
              console.log(response)
              setAssignments(response);
              setIsLoading(false)
            })
            .catch(error => {
                console.error('Error fetching assignments:', error);
                setIsLoading(false)
            });
    }

    if (props.weeksAgo) {
      fetchAssignments();
    }
    
  }, [props.weeksAgo]);
  return (
     <>
      <div className="w-full flex flex-col justify-center">
      {assignments && !isLoading ? 
      <div className="m-8 flex flex-col gap-8">
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <div className="p-8 flex justify-between items-center">
            <Statistic title="Number of Assignments" value={assignments.length} />
            <Statistic title="Average Grade" value={Math.round(assignments.reduce((acc, curr) => acc + curr.grade, 0) / assignments.length)} />
            <Statistic title="% Student-Based Assignments" value={Math.round(assignments.filter(item => item.assignment_type === "Student-Based Assignment").length / assignments.length * 100)} />
        </div>
      </div>
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <div className="p-8 flex justify-between items-center">
              <Statistic title="# Primary School" value={assignments.filter(item => item.grade <= 6).length} />
              <Statistic title="# Secondary School" value={assignments.filter(item => item.grade > 6 && item.grade < 13).length} />
              <Statistic title="# Tertiary" value={assignments.filter(item => item.grade === 13).length} />
          </div>
      </div>
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <Table 
          pagination={{ pageSize: 100 }}  
          columns={COLUMNS} 
          dataSource={assignments} 
          onRow={(record) => ({
            onClick: () => {
              props.history.push(`/teacher/assignment/${record._id}`);
            },
          })}
        />
      </div>
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default AssignmentDashboard;

