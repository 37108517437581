import React, { useContext, useEffect, useState } from 'react';
import { Form, Select, Input, Button, Card, Checkbox } from 'antd';
import { toast } from 'react-toastify';
import { SherpaContext } from '../utils/sherpaContext';
import { updateUser } from '../api/user';

const Onboarding = (props) => {
    const [onboardingForm] = Form.useForm();
    const [role, setRole] = useState(null);
    const [ofAge, setOfAge] = useState(null)
    const { userToken, setUserToken } = useContext(SherpaContext)
    
    const queryParams = new URLSearchParams(window.location.search);
    const assignmentid = queryParams.get('assignmentid');

    const redirect = () => {
        if (userToken && userToken?.details?.role) {
            if (assignmentid) {
                props.history.push('/student/assignment/' + assignmentid)
            } else {
                if (userToken.details.role === "student"){
                    props.history.push('/student')
                } else if (userToken.details.role === "teacher") {
                    props.history.push('/teacher/gettingstarted')
                }
            }
        }
    }

    useEffect(() => {
        redirect()
    }, [userToken])

    const onFinish = async (values) => {
        try {
            // Update server side 
            await updateUser({
                ...values,
                "max_conversations": 3,
                "status": "onboarded"
            });

            // Update locally
            setUserToken({
                "user": userToken.user,
                "details": {
                    ...userToken.details,
                    ...values,
                    "max_conversations": 3,
                    "status": "onboarded"
                }
            })
            
        } catch (error) {
            // Handle error
            toast.error(error.message);
        }
    };


    return (
        <div className="flex w-full h-screen justify-center items-center">
            <Card className="w-[800px]">
                <div className="text-3xl font-bold text-center mb-8 mt-4">Welcome!</div>
                <Form
                    form={onboardingForm}
                    layout="vertical"
                    className="w-full"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="What is your full name?"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your name!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Are you above 18?"
                        name="above_18"
                        rules={[
                            {
                                required: true,
                                message: "Please state if you are older than 18.",
                            },
                        ]}  
                    >
                        <Select
                            onChange={(value)=>{
                                setOfAge(value)
                            }}
                        >
                            <Select.Option value={true}>Yes, I am 18+</Select.Option>
                            <Select.Option value={false}>No, I am under 18</Select.Option>
                        </Select>
                    </Form.Item>
                    
                    {/* <Form.Item name="birthdate" label="What is your birth date?" rules={[
                            {
                                required: true,
                                message: "Please select your role!",
                            },
                        ]}>
                        <DatePicker className="w-full" />
                    </Form.Item> */}
                    <Form.Item
                        label="Are you a student or a teacher?"
                        name="role"
                        rules={[
                            {
                                required: true,
                                message: "Please select your role!",
                            },
                        ]}  
                    >
                        <Select
                            onChange={(value)=>{
                                setRole(value)
                            }}
                        >
                            <Select.Option value="student">Student</Select.Option>
                            <Select.Option value="teacher">Teacher</Select.Option>
                        </Select>
                    </Form.Item>



                    {role === "teacher" ? 
                        <>
                            <Form.Item
                                label="What subjects do you teach?"
                                name="subjects"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select your subjects!",
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                >
                                    <Select.Option value="english">English</Select.Option>
                                    <Select.Option value="history">History</Select.Option>
                                    <Select.Option value="computer_science">Computer Science</Select.Option>
                                    <Select.Option value="math">Math</Select.Option>
                                    <Select.Option value="science">Science</Select.Option>
                                    <Select.Option value="geography">Geography</Select.Option>
                                    <Select.Option value="language">Language</Select.Option>
                                    <Select.Option value="social_science">Social Science</Select.Option>
                                    <Select.Option value="other">Other</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="What grade is this task for?"
                                name="grade"
                                rules={[
                                    { required: true, message: "This can't be empty." },
                                ]}
                                >
                                <Select>
                                <Select.Option value={1}>1st grade</Select.Option>
                                <Select.Option value={2}>2nd grade</Select.Option>
                                <Select.Option value={3}>3rd grade</Select.Option>
                                <Select.Option value={4}>4th grade</Select.Option>
                                <Select.Option value={5}>5th grade</Select.Option>
                                <Select.Option value={6}>6th grade</Select.Option>
                                <Select.Option value={7}>7th Grade</Select.Option>
                                <Select.Option value={8}>8th Grade</Select.Option>
                                <Select.Option value={9}>9th Grade</Select.Option>
                                <Select.Option value={10}>10th Grade</Select.Option>
                                <Select.Option value={11}>11th Grade</Select.Option>
                                <Select.Option value={12}>12th Grade</Select.Option>
                                <Select.Option value={13}>College</Select.Option>
                                
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="What school do you teach at?"
                                name="school"
                                rules={[
                                    { required: true, message: "This can't be empty." },
                                ]}
                                >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Where did you hear about us?"
                                name="source_reference"
                                rules={[
                                    { required: true, message: "This can't be empty." },
                                ]}
                                >
                                <Input />
                            </Form.Item>
                        </>
                        
                    : null
                    }

                    {ofAge === true ?
                     <Form.Item
                        name="checked"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                message: "Please read the privacy policy for Sherpa before proceeding.",
                            },
                        ]}
                    >
                     <Checkbox>I have read and agree to the <a href="https://sherpalabs.co/privacy-policy" target='_blank' rel="noreferrer">Privacy Policy</a>
                    </Checkbox>
                    </Form.Item>

                     : ofAge === false ? 
                     <Form.Item
                        name="checked"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                message: "Please verify that you have receive consent to use Sherpa.",
                            },
                        ]}
                    >
                     <Checkbox>
                        I have permission from a teacher or a supervising adult to use Sherpa
                     </Checkbox>
                    </Form.Item> : null}

                    <div className="w-full justify-end flex">
                        <Button htmlType='submit' type="primary" style={{ background: '#9C1FFF' }}>Next!</Button>
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default Onboarding;

