import SherpaClient from "./main";
import axios from "axios";

// Get User
export const getUser = async () => {
  const response = await SherpaClient.get("/user");
  return response.data;
};

// Update User
export const updateUser = async (updated_info) => {
  const response = await SherpaClient.post("/user", updated_info);
  return response.data;
};

// Get Custom Token
export const getCustomToken = async (email) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/user/custom/${email}`
  );

  return response.data;
};
