// Get all student conversations for assignment
import SherpaClient from "./main";

export const getAllStudentConversationsForAssignment = async (
  student_id,
  assignment_id
) => {
  const response = await SherpaClient.get(
    `/dashboard/conversations/${student_id}/${assignment_id}`
  );
  return response.data;
};

// Get all videos for conversation
export const getAllVideosForConversation = async (conversation_id) => {
  const response = await SherpaClient.get(
    `/dashboard/videos/${conversation_id}`
  );
  return response.data;
};

// Get students with conversations
export const getStudentsWithConversations = async (assignment_id) => {
  const response = await SherpaClient.get(
    `/dashboard/studentsWithConversations/${assignment_id}`
  );
  return response.data;
};
