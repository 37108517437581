import React, {useContext, useEffect, useState} from 'react';
import { getAllConversations } from '../api/conversation';
import ReactLoading from "react-loading";
import { Table, Statistic, Tag } from 'antd'
import { AiOutlineLeft } from "react-icons/ai"
import { toast } from 'react-toastify';
import { convertTimeToTimeAgo, convertUtcToNow } from '../utils/general'
import { SherpaContext } from '../utils/sherpaContext';
import { getAllAssignments } from '../api/assignment';


const TeacherSubmissions = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [conversations, setConversations] = useState(null);
  const [totalConversations, setTotalConversations] = useState(null);
  const [assignments, setAssignments] = useState(null);

  const queryParams = new URLSearchParams(props.location.search);
  
  const getFilteredAssignments = () => {
    return queryParams.get('assignments')?.split(",")
  }

  const getCurrentPage = () => {
    return queryParams.get('page') ? parseInt(queryParams.get('page')) : 1
  }
  console.log(conversations);

  
  const COLUMNS = [
  {
    title: 'Student',
    dataIndex: 'student',
    key: 'student',
    width: '10%',
    render: (student) => {
      return student.name
    }
  },
  {
    title: 'Status',
    dataIndex: 'evals',
    width: '10%',
    // sorter: (a, b) => a.evals.items.filter(item => item.status === "warning").length - b.evals.items.filter(item => item.status === "warning").length,
    render: (evaluations) => {
      return evaluations.items.filter(item => item.status === "warning").length > 0 ? 
        <Tag color="#fca24e" className="status-tag">{evaluations.items.filter(item => item.status === "warning").length} {evaluations.items.filter(item => item.status === "warning").length > 1 ? "improvements" : "improvement"}</Tag> 
      : evaluations.items.length > 0 ?
        <Tag color="#87d068" className="status-tag">Verified</Tag> 
      : evaluations.is_complete ? 
        <Tag color="lime" className="status-tag">Completed</Tag>
      : <Tag color="orange" className="status-tag">In Progress</Tag>

    }
  },
  {
    title: 'Assignment',
    dataIndex: 'assignment',
    key: 'assignment',
    width: '20%',
    filters: assignments ? assignments.map(item => {
      return {
        "value": item.id,
        "text": `${item.title.substring(0, 100)}${item.title.length > 100 ? "..." : ""}`
      }
    }) : [],
    filteredValue: getFilteredAssignments(),
    filterMode: 'single',
    render: (assignment) => {
      return `${assignment.title.substring(0, 100)}${assignment.title.length > 100 ? "..." : ""}`
    }
  },
  {
    title: 'Student Feedback',
    dataIndex: 'feedback',
    key: 'feedback',
    width: '30%',
    render: (feedback) => {
      // return <div>
      //   {feedback?.map(item => item?.bug_error_message).join("\n")} <br /> <br />
      //   {feedback.map(item => item?.bug_metadata?.classroom_breakin).join("\n")}
      // </div>
      return feedback.length > 0 ? <div>
        {feedback[0].bug_error_message} <br/> <br/>
        {feedback[0].bug_metadata.classroom_breakin}
      </div> : null
    }
  },
  {
      title: 'Created At',
      dataIndex:'created_at',
      key:'created_at',
      width: '10%',
      render: (timestamp) => {
        const now = convertUtcToNow(timestamp)
        return convertTimeToTimeAgo(now)
      }
  },
  {
    title: 'Completed',
    dataIndex: 'completed_time',
    key: 'completed_time',
    width: '10%',
    render: (timestamp) => {
      if (timestamp) {
        const now = convertUtcToNow(timestamp)
        return convertTimeToTimeAgo(now)
      } else {
        return 'N/A'
      }
    }
  },
  
]

  const fetchData = async () => {
    setIsLoading(true)
    const conversationsData = await getAllConversations(getFilteredAssignments(), getCurrentPage(), false, false, false, false, false)
    setConversations(conversationsData.conversations)
    setTotalConversations(conversationsData.count)
    setAssignments(await getAllAssignments())
    setIsLoading(false)
  }
  

  useEffect(() => {
    fetchData();
  }, [queryParams.get("assignments"), queryParams.get("page")]);


  return (
     <>
      <div className="bg-white w-full flex justify-between items-center" style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
        {getFilteredAssignments() && <div className="flex items-center cursor-pointer pl-4 gap-2" onClick={()=>{
          props.history.goBack();
        }}>
          <AiOutlineLeft />
          Back
        </div>}
        <div className="text-md m-4" style={{
          fontWeight: 600
        }}>Student Submissions</div>
        <div className="w-32"></div>
      </div>
      <div className="w-full flex justify-center">
      {!isLoading ? 
      <div className="flex flex-col gap-8 m-8 w-full">
        <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
          <div className="p-8 flex justify-between items-center">
              <Statistic title="Number of Conversations" value={totalConversations?.[0]?.total_count} />
          </div>
        </div>
        <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
          <Table style={{
            fontSize: '20px !important'
          }} 

          onRow={(record) => {
            return {
              onClick: event => {
                props.history.push('/teacher/conversation/' + record._id)
              },
              onMouseEnter: event => {
                event.target.style.cursor = 'pointer';
              },
              onMouseLeave: event => {
                event.target.style.cursor = 'pointer';
              },
            };
          }}
          rowClassName={() => 'clickable-row'}
          columns={COLUMNS} dataSource={conversations} onChange={(pagination, filters, sorter, extra) => {
            // update assignment ids
            console.log(filters)
            const assignment_ids = filters.assignment;
            if (assignment_ids) {
              const assignmentsString = assignment_ids.join(',');
              queryParams.set('assignments', assignmentsString);
              props.history.push({search: queryParams.toString()});
            } else {
              queryParams.delete('assignments')
              props.history.push({search: queryParams.toString()});
            }

            // pagination
            if (pagination?.current){
              queryParams.set('page', pagination?.current);
              props.history.push({search: queryParams.toString()});
            }

          }} 
          pagination={{ position: ['topRight'],current: getCurrentPage(), pageSize: 20, total: totalConversations?.[0]?.total_count, showSizeChanger: false }} />
        </div>
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default TeacherSubmissions;


