import React, { useContext, useEffect, useState } from 'react'
import { lti_submitDeepLink, lti_getAssignments } from '../api/lti';
import { Button, Card, Form, Input, Select } from 'antd'
import Logo from '../img/sherpalogo.svg'
import { SherpaContext } from '../utils/sherpaContext';
import { IoDocumentTextOutline, IoBook } from "react-icons/io5"

const DeepLinkPage = (props) => {

    const { ltiToken } = useContext(SherpaContext)
    const [assignmentType, setAssignmentType] = useState(null)
    const [assignments, setAssignments] = useState()
    const [assignmentCreationForm] = Form.useForm();

    const onSubmit = async (values) => {
        const lti_assignment = JSON.parse(values.lti_assignment)
        console.log(lti_assignment)
        const response = await lti_submitDeepLink({
            ...values,
            lti_assignment
        }, ltiToken.ltik)
        var divElement = document.createElement("div"); 
        divElement.innerHTML = response.form;
        document.body.appendChild(divElement);
        
        document.getElementById("ltiaas_dl").submit()
    }
  
    useEffect(()=>{
        const getAssignments = async () => {
            const assignments = await lti_getAssignments(ltiToken.token.launch.context.id)
            setAssignments(assignments)
        }

        getAssignments()
    }, [])

  return assignments && (
    <>
    <div className={`bg-white w-full flex justify-center items-center h-16`} style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
        <img src={Logo} className="w-24 mx-8"/>
    </div>
          <div className="w-full flex py-16 justify-center flex-col items-center gap-8">
            <div className="rounded-lg bg-[#EEECFF] w-[800px]">
            <div className="px-8 py-6 flex items-start justify-between ">
                <div className="text-purple-800 flex flex-col gap-2">
                <span className="text-lg" style={{fontWeight: '600'}}>Scale Your Conversational Ability</span>
                <span className="text-sm">Pick an assignment you've assigned to your students, and our AI will talk to your students, <b>1-on-1</b>, about their submission in an engaging, personalized manner.</span>
                </div>
            </div>
            </div>
            <div className="w-[800px]">
            <div className="w-full flex gap-4">
              
                <div onClick={()=>{
                    setAssignmentType("Reading Response")
                }} className={`w-full rounded-lg flex flex-col text-sm hover:bg-[#f7f7f7] cursor-pointer ${assignmentType === "Reading Response" ? "bg-[#EEECFF] hover:bg-[#EEECFF]" : null}`} style={{border: assignmentType === "Reading Response" ? '1.5px solid #c3abd8' : '1.5px solid #E2E8F0', color: assignmentType === "Reading Response" ? '#6B22A8' : 'black'}} >
                <div className="w-full">
                    <div className="p-4 flex gap-2 flex-col">
                        <div className="flex items-center gap-2"><IoBook /><b className="text-md">Reading Response</b> </div>
                        <div>Submit any text-based digital media you assigned to your students, and we will simulate a conversation with them about what they read.</div>
                    </div>
                </div>
                </div>
                
                <div onClick={()=>{
                    setAssignmentType("Student-Based Assignment")
                }} className={`w-full rounded-lg flex flex-col text-sm hover:bg-[#f7f7f7] cursor-pointer ${assignmentType === "Student-Based Assignment" ? "bg-[#EEECFF] hover:bg-[#EEECFF]" : null}`} style={{border: assignmentType === "Student-Based Assignment" ? '1.5px solid #c3abd8' : '1.5px solid #E2E8F0', color: assignmentType === "Student-Based Assignment" ? '#6B22A8' : 'black'}} >
                  <div className="w-full">
                    <div className="p-4 flex gap-2 flex-col">
                        <div className="flex items-center gap-2"><IoDocumentTextOutline /><b className="text-md">Student-Based Assignment</b> </div>
                        <span>Have your students write an essay, lab-report, infographic on any topic of your choice, and we will simulate a conversation with them about their writing to gauge their true understanding.</span>
                    </div>
                  </div>
                </div>

            </div>
          </div>
          {assignmentType === "Student-Based Assignment" ? 
        <div className="rounded-lg bg-white w-[800px]" style={{border: '1.5px solid #E2E8F0'}} >
            <Form
                form={assignmentCreationForm}
                layout="vertical"
                className="w-full overflow-auto p-8"
                onFinish={onSubmit}
            >
                 <Form.Item
                    label="Which assignment do you want your students to speak on?"
                    name="lti_assignment"
                    rules={[
                        { required: true, message: "This can't be empty." },
                      ]}
                    >
                    <Select>
                        {assignments.map((assignment)=>{
                            return (
                                <Select.Option value={JSON.stringify(assignment)}>{assignment.title}</Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                <div className="text-gray-400 pb-4">
                    We will only allow students to <b>speak on it</b> if they have submitted their assignment.<br />

                    Assignments listed include only those where <b>files</b> can be submitted.
                </div>
                
                <Form.Item
                    label="Remind me, what did you want your students to do for this assignment?"
                    name="task_prompt"
                    rules={[
                        {
                        required: true,
                        message: "Your prompt can't be empty!",
                        },
                    ]}
                    >
                    <Input placeholder="Write a 3000-word essay on something that they care about..."/>
                </Form.Item>
                <div className="text-gray-400 pb-4">
                    This helps our AI ask relevant questions related to the assignment.
                </div>

                <Form.Item
                    label="What grade is this task for?"
                    name="grade"
                    rules={[
                        { required: true, message: "This can't be empty." },
                      ]}
                    >
                    <Select>
                      <Select.Option value={1}>1st grade</Select.Option>
                      <Select.Option value={2}>2nd grade</Select.Option>
                      <Select.Option value={3}>3rd grade</Select.Option>
                      <Select.Option value={4}>4th grade</Select.Option>
                      <Select.Option value={5}>5th grade</Select.Option>
                      <Select.Option value={6}>6th grade</Select.Option>
                      <Select.Option value={7}>7th Grade</Select.Option>
                      <Select.Option value={8}>8th Grade</Select.Option>
                      <Select.Option value={9}>9th Grade</Select.Option>
                      <Select.Option value={10}>10th Grade</Select.Option>
                      <Select.Option value={11}>11th Grade</Select.Option>
                      <Select.Option value={12}>12th Grade</Select.Option>
                      <Select.Option value={13}>College</Select.Option>
                    </Select>
                </Form.Item>
                <div className="text-gray-400 pb-4">
                        This helps our AI adapt to the student's grade level.
                    </div>
                <div className="w-full flex items-center justify-end">
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ background: '#9C1FFF'}}
                    >
                        Finish
                    </Button>
                </div>
            </Form>
        </div>
        : assignmentType === "Reading Response" ? 
        <></>
        : null 
        }
    </div>
    </>
  )
}

export default DeepLinkPage;