import axios from "axios";
import SherpaClient from "./main";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;

export const lti_setupDeepLink = async (ltik) => {
  const url = `${BACKEND_API_URL}/lti/deep-link?ltik=${ltik}`;

  try {
    const response = await axios.get(url);
    const data = response.data;

    return data;
  } catch (error) {
    // Handle any errors that occur during the request
    console.error(error);
  }
};
export const lti_getAssignments = async (course_id) => {
  const response = await SherpaClient.get(
    `/lti/assignments?course_id=${course_id}`
  );
  return response.data;
};
export const lti_getStudentSubmission = async (
  course_id,
  lti_user_id,
  assignment_id
) => {
  const response = await SherpaClient.get(
    `/lti/student_submission?course_id=${course_id}&user_id=${lti_user_id}&assignment_id=${assignment_id}`
  );
  return response.data;
};

export const lti_submitDeepLink = async (assignment_details, ltik) => {
  const response = await SherpaClient.post(
    `/lti/submit-link?ltik=${ltik}`,
    assignment_details
  );
  return response.data;
};

export const lti_submitAssignment = async (ltik) => {
  const response = await SherpaClient.post(
    `/lti/submit-assignment?ltik=${ltik}`
  );
  return response.data;
};
