import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { Button, Form, Input, Card, Alert } from 'antd' 
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const submitHandler = async (e) => {
        //e.preventDefault();
        console.log("Submit handler triggered");
        try {
            console.log("Attempting to send password reset email");
            await sendPasswordResetEmail(auth, email);
            console.log("Password reset email sent");
            setMessage('Check your inbox for further instructions');
        } catch (err) {
            console.log("Error in sending password reset email: ", err);
            setError('Failed to reset password');
        }
    };

    return (
        <div>
            <Card title="Forgot Password">
                {error && <Alert message={error} type="error" />}
                {message && <Alert message={message} type="success" />}
                <Form onFinish={submitHandler}>
                    <Form.Item label="Email">
                        <Input
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default ForgotPassword;
