import { fetchEventSource } from "@microsoft/fetch-event-source";
import { getAuthToken } from "./main";
import { toast } from "react-toastify";
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;

export const getAIQuestion = async (
  conversation,
  assignment,
  up_to,
  transcript,
  setAIQuestion,
  name,
  setAIState,
  type,
  last_question_to_retry = null
) => {
  const token = await getAuthToken();

  const url = BACKEND_API_URL + "/chat/getAIQuestion";
  const data = {
    up_to: up_to,
    transcript: transcript,
    conversation: conversation,
    assignment: assignment,
    name: name,
    type: type,
    last_question_to_retry,
  };

  // Prevent this from happening twice ...
  await fetchEventSource(url, {
    method: "POST",
    openWhenHidden: true,
    headers: {
      Accept: "text/event-stream",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
    onopen(res) {
      if (res.ok && res.status === 200) {
        console.log("Connection made ", res);
      } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        toast.error("Error streaming message!");
      }
    },
    onmessage(event) {
      setAIState("talking");
      setAIQuestion(event.data);
    },
    onclose() {
      setAIState("waiting");
    },
    onerror(err) {
      toast.error("Error streaming message!");
      setAIState("waiting");
    },
  });

  setAIState("waiting");
};
