import React, {useContext, useEffect, useState} from 'react';
import { deleteAssignment, getAssignment, updateAssignment as apiUpdateAssignment, migrateAssignmentToV2 } from '../api/assignment'
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { Button, List, Card, Input, Switch, Tooltip } from 'antd'
import Logo from '../img/sherpalogo.svg'
import { SherpaContext } from '../utils/sherpaContext';
import { BsFillPlayFill} from "react-icons/bs";
import { AiOutlineLeft } from "react-icons/ai";
import { toast } from 'react-toastify';
import { Steps as IntroSteps } from 'intro.js-react';
import { getStudentsWithConversations } from '../api/dashboard'
import { getAllConversations } from '../api/conversation';
import { Modal } from 'antd'
import { convertUtcToNow } from '../utils/general';
import InfoModal from '../components/InfoModal';
import EditAssignmentModal from '../components/EditAssignmentModal';
import { FiEdit2 } from "react-icons/fi";

const INTRO_STEPS = [
{
    title: 'Preview Conversation!',
    intro: 'Curious to see what the conversation looks like for your students? Preview it now!',
    element: '#preview_button'
  },
]


const FIELD_MAPPINGS = {
  "task_prompt": "Task Prompt",
  "subject": "Subject",
  "grade": "Grade",
  "assignment_type": "Assignment Type",
  "created_at": "Created At",
  "external_source_title": "External Source Title",
  "external_format": "External Source Format",
  "conversation_flow": "Conversation Flow",
  "time_limit": "Time Limit?",
  "min_words": "Min Word Response?",
  "time_limit_seconds": "Length of Time Limit",
  "version": "Version"
}
const TeacherAssignment = (props) => {
  const [assignment, setAssignment] = useState(null)
  const [assignmentDetails, setAssignmentDetails] = useState(null)
  const [students, setStudents] = useState(null)
  const [isUpgrading, setIsUpgrading] = useState(false)
  
  const [readingModalOpen, setReadingModalOpen] = useState(false)
  const { ltiToken, userToken, updateIntroStatus } = useContext(SherpaContext)
  const [stepsEnabled, setStepsEnabled] = useState(!userToken?.details?.intro_status?.view_assignment  && !ltiToken)
  const [showAIFeedbackImmediately, setShowAIFeedbackImmediately] = useState(null)
  const [isEditAssignmentModalOpen, setIsEditAssignmentModalOpen] = useState(false)

  const { assignmentid } = useParams();

  const updateAssignment = async (request_body) => {
    let new_assignment = {...assignment, ...request_body};
    setAssignment(new_assignment);
  }

  useEffect(()=>{
    if (assignment) {
      setShowAIFeedbackImmediately(assignment.student_ai_feedback)
    }
  }, [assignment])
  useEffect(()=>{
    const fetchAssignment = async () => {
      setAssignment(await getAssignment(assignmentid))
      setStudents(await getStudentsWithConversations(assignmentid))
    }

    const fetchAssignmentStudentDetails = async () => {
      const convoData = await getAllConversations([assignmentid], 1, true)
      setAssignmentDetails(convoData.conversations)
    }
    if (assignmentid) {
      fetchAssignment()
      fetchAssignmentStudentDetails();
    }
    
  }, [assignmentid])

  return assignment && assignmentDetails && students && showAIFeedbackImmediately !== null ? (
    <>
    {assignment.text ? 
    <InfoModal 
      isOpen={readingModalOpen}
      setIsOpen={setReadingModalOpen}
      title="Reading"
      main={
        <div>
          {(assignment.original_text ?? assignment.text ).split("\n").map(para => (
            <p>{para}</p>
          ))}
        </div>
      }
    />
    : null }
  <IntroSteps
      enabled={stepsEnabled}
      steps={INTRO_STEPS}
      initialStep={0}
      options={{
        doneLabel: "Done",
        exitOnOverlayClick: false,
        exitOnEsc: false,
      }}
      onExit={()=>{
        setStepsEnabled(false)
      }}
      onComplete={async ()=>{
        await updateIntroStatus("view_assignment")
      }}
      
    />
     <div className={`bg-white w-full flex justify-${ltiToken ? 'center' : 'between'} items-center h-16`} style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
      
      {ltiToken ? 
        <img src={Logo} className="w-24" />
        :
        <>
          <div className="flex items-center cursor-pointer pl-4 gap-2" onClick={()=>{
            props.history.goBack();
          }}>
            <AiOutlineLeft />
            Back
          </div>
            <div className="text-md m-4">{assignment.title.substring(0, 100)}{assignment.title.length > 100 ? "..." : ""}</div>
            <div className="flex gap-2">
              {userToken.details.admin && assignment.assignment_type === "Reading Responses" ? 
              // assignment.version === "v2"
                <Button disabled={true} type="dashed" loading={isUpgrading} onClick={async ()=>{
                  setIsUpgrading(true)
                  const results = await migrateAssignmentToV2(assignmentid)
                  toast.success("Successfully indexed content and added " + results.questions_added.toString() + " questions!")
                  setAssignment({
                    ...assignment,
                    "version": "v2"
                  })
                  setIsUpgrading(false)
                }}>
                  Upgrade to V2
                </Button>  
                : null 
              }
              <Button type="primary" style={{ background: '#9C1FFF'}} className="mr-4" onClick={()=>{
                navigator.clipboard.writeText(window.location.origin + "/student/assignment/" + assignmentid)      
                toast.success("Copied Share Link to Clipboard!")
              }}>Share</Button>
            </div>
          </>
      }
        
    </div>
      <div className="w-full flex py-16 justify-center flex-col items-center gap-8">
        
          <div className="rounded-lg bg-[#EEECFF] w-[800px]">
            <div className="px-8 py-6 flex items-start justify-between">
              <div className="text-purple-800 flex flex-col">
                <span className="text-lg" style={{fontWeight: '600'}}>
                  {assignmentDetails.length === 0 ? 'No students have spoken about the reading' :
                  assignmentDetails.length === 1 ? '1 student has spoken about the reading' :
                  `${assignmentDetails.length} students have spoken about the reading`}
                </span>
                <span className="text-sm">
                  {
                    assignmentDetails.filter(detail => detail.evals && detail.evals.items.some(item => item.status === 'warning')).length === 0 ? 'No students have warnings' :
                    assignmentDetails.filter(detail => detail.evals && detail.evals.items.some(item => item.status === 'warning')).length === 1 ? '1 student has a warning' :
                    `${assignmentDetails.filter(detail => detail.evals && detail.evals.items.some(item => item.status === 'warning')).length} students have warnings`
                  }
                </span>
              </div>
              {assignmentDetails.length > 0 && 
                <Button type="primary" style={{ background: '#9C1FFF'}} className="text-md flex flex-row items-center gap-2" onClick={()=>{
                  props.history.push('/teacher/submissions?assignments=' + assignmentid)
                }}><span>See Student Submissions</span></Button>
              }
            </div>
          </div>
        <div className="rounded-lg bg-white w-[800px]" style={{border: '1.5px solid #E2E8F0'}} >
          <div className="px-8 py-6 flex items-center justify-between">
            <div className="flex flex-col my-2">
              <span className="text-lg" style={{fontWeight: '600'}}>Preview Conversation</span>
              <span className="text-sm">How will this conversation look like for students?</span>
            </div>
            <div className="flex flex-row items-center gap-2">
              {assignment.version === "v2" ? 
            <Button onClick={()=>{
                props.history.push('/teacher/assignment/' + assignmentid + '/questions')
              }}>See Questions</Button>
              : null }
            <Button icon={<BsFillPlayFill size={22}/>} onClick={()=>{
              props.history.push('/student/assignment/' + assignmentid)
            }} id="preview_button"/>
            </div>

          </div>
        </div>
        <div className="rounded-lg bg-white w-[800px]" style={{border: '1.5px solid #E2E8F0'}} >
          <div className="px-8 py-6 flex flex-col gap-4 my-2">
            <span className="text-lg" style={{fontWeight: '600'}}>Share Link With Your Students</span>
            <div className="flex flex-row gap-4 w-full">
              <Input style={{height: '40px'}}value={window.location.origin + "/student/assignment/" + assignmentid} />
              <Button className="text-white font-semibold cursor-pointer" size='large' type='primary'
                    onClick={async ()=>{
                      navigator.clipboard.writeText(window.location.origin + "/student/assignment/" + assignmentid)      
                      
                      toast.success("Copied Share Link to Clipboard!")
                    }}
                >
                    Copy Link
                </Button>
              </div>
          </div>
      </div>
      <div className="rounded-lg bg-white w-[800px]" style={{border: '1.5px solid #E2E8F0'}} >
          <div className="px-8 py-6 flex items-center justify-between">
            <div className="flex flex-col my-2">
              <span className="text-lg" style={{fontWeight: '600'}}>Show AI Feedback Immediately?</span>
              <span className="text-sm">After the conversation, students will be able to see Sherpa's evaluation instantly on their dashboard.</span>
            </div>
            <Switch checked={showAIFeedbackImmediately} onChange={async value => {
              await apiUpdateAssignment(assignmentid, {
                student_ai_feedback: value
              })

              updateAssignment({
                student_ai_feedback: value
              })
            }}/>

          </div>
        </div>
        <div className="rounded-lg bg-white w-[800px]">
          <List
            style={{border: '1.5px solid #E2E8F0'}}
            size="large"
            header={
              <div className="flex justify-between items-center">
                <b>Assignment Details</b>
                <Button 
                  type="primary" 
                  style={{ backgroundColor: '#9C1FFF', borderColor: '#9C1FFF', color: 'white' }} 
                  onClick={() => setIsEditAssignmentModalOpen(true)}
                >
                  Edit Assignment
                </Button>
              </div>
            }
            bordered
            dataSource={Object.keys(assignment)}
            renderItem={(item) => 
              Object.keys(FIELD_MAPPINGS).includes(item) && (assignment[item] || item === "time_limit") && item !== "conversation_flow" ? 
                <List.Item>
                  <div className="w-full flex justify-between items-center gap-16">
                    <span>{FIELD_MAPPINGS[item]}</span>
                    <span>{item === "time_limit" ? (assignment[item] ? "Yes" : "No") : item === "created_at" ? convertUtcToNow(assignment[item]).toLocaleString() : assignment[item]}</span>
                  </div>
                </List.Item> : 
                Object.keys(FIELD_MAPPINGS).includes(item) && assignment[item] && item === "conversation_flow" ? 
                <List.Item>
                  <div className="w-full flex justify-between items-center text-right">
                    <span>Concepts</span>
                    <span>{assignment[item].map(question => {
                      return question.type === "base" && question.concept ? (
                        <p>{question.concept}</p>
                      ) : null
                    })}</span>
                  </div>
                </List.Item>
                :
                null
            }
          />
          <EditAssignmentModal
            isOpen={isEditAssignmentModalOpen}
            setIsOpen={setIsEditAssignmentModalOpen}
            updateAssignment={updateAssignment}
            initialValues={{
              ...assignment,
              "concepts": assignment.conversation_flow
                .filter(question => question.type === "base")
                .map(question => question.concept || "")
            }}
            assignment_id={assignmentid}
          />
        </div>
        <Card style={{border: '1.5px solid #E2E8F0'}} className="w-[800px]">
          <div className="w-full flex gap-4">
              {assignment.text ? 
                <Button className="w-full flex text-center justify-center" onClick={()=>{
                  setReadingModalOpen(true)
                }}>View Reading</Button>
              : null }
              <Button className="w-full flex text-center justify-center" danger onClick={()=>{
                Modal.confirm({
                  title: 'Are you sure you want to delete this assignment? You will delete all associated conversations to this assignment.',
                  onOk: async () => {
                    await deleteAssignment(assignmentid)
                    props.history.push('/teacher')
                    toast.success("Assignment deleted!")
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                });
              }}>Delete Assignment</Button>
            </div>
        </Card>
        {/* {{assignment && assignment.text ? 
          <Card style={{border: '1.5px solid #E2E8F0'}} className="w-[800px]">
            {assignment.text}
          </Card>
        : null}} */}
      </div>
  </>
  ) :
  <div className="w-full h-full flex items-center justify-center">
    <ReactLoading
      type={"spin"}
      color={"purple"}
      height={30}
      width={30}
    />
  </div>
}

export default TeacherAssignment;
