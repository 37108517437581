import React from "react"
import { Modal, Form, Input, Button } from "antd"
import { reportBug } from "../api/bugreports"
import { toast } from "react-toastify"


const TuneConvoModal = ({isOpen, setIsOpen}) => {

    return (
        <Modal style={{
            zIndex: 999999999999999999999

        }} title="Feedback" open={isOpen} footer={[
        ]} onCancel={() => {
          setIsOpen(false)
        }}>
            <p></p>
            <Form
                onFinish={async (values) => {
                    await reportBug(values.details, "Tune Convo", {
                        "type": "tune_convo"
                    })

                    toast.success("Thanks for your feedback!")
                    setIsOpen(false)
                }}
                layout="vertical"
            >
                <Form.Item
                    name="details"
                    label="Is this framework appropriate for your class? How would you change it?"
                    rules={[{ required: true, message: 'This is required!'}]}
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>
                <div className="w-full justify-end flex">
                    <Button htmlType="submit" type="primary" style={{
                        background: '#9C1FFF'
                    }}>
                        Submit Feedback
                    </Button>
                </div>
            </Form>
          </Modal>
    )
}

export default TuneConvoModal