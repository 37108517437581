import SherpaClient from "./main";

export const getTeachers = async (weeksAgo) => {
  const response = await SherpaClient.post("/admin/users", {
    role: "teacher",
    weeks_ago: weeksAgo,
  });

  return response.data;
};

export const getBugReports = async (weeksAgo) => {
  const response = await SherpaClient.post("/admin/bugreports", {
    role: "teacher",
    weeks_ago: weeksAgo,
  });

  return response.data;
};

export const getStudents = async (weeksAgo) => {
  const response = await SherpaClient.post("/admin/users", {
    role: "student",
    weeks_ago: weeksAgo,
  });

  return response.data;
};

export const getConversations = async (weeksAgo) => {
  const response = await SherpaClient.post("/admin/conversations", {
    weeks_ago: weeksAgo,
  });

  return response.data;
};

export const getEvaluations = async (weeksAgo) => {
  const response = await SherpaClient.post("/admin/evaluations", {
    weeks_ago: weeksAgo,
  });

  return response.data;
};

export const getAssignments = async (weeksAgo) => {
  const response = await SherpaClient.post("/admin/assignments", {
    weeks_ago: weeksAgo,
  });

  return response.data;
};
