import React, {useContext, useEffect, useState} from 'react';
import { getAllAssignments } from '../api/assignment'
import ReactLoading from "react-loading";
import { Table, Modal, Button } from 'antd'
import { AiOutlinePlus } from "react-icons/ai"
import { toast } from 'react-toastify';
import { convertTimeToTimeAgo, convertUtcToNow } from '../utils/general'
import { deleteAssignment } from '../api/assignment';

import { SherpaContext } from '../utils/sherpaContext';


const TeacherHome = (props) => {
  const [assignments, setAssignments] = useState(null);

  const COLUMNS = [
  {
    title: 'Title',
    width: '80%',
    dataIndex: 'title',
    key: 'title',
    render: (title) => {
      if (title?.length > 100) {
        return title.substring(0, 100) + "..."
      } else {
        return title
      }
    }
  },
  {
    title: 'Created',
    width: '10%',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (timestamp) => {
      const now = convertUtcToNow(timestamp)
      return convertTimeToTimeAgo(now)
    }
  },
  {
    title: 'Action',
    key: 'action',
    width: '10%',
    render: (_, record) => (
      <div className="flex gap-2">
        <Button onClick={()=>{
        props.history.push('/teacher/assignment/' + record.id)          
     }}>
        View
     </Button>
     <Button type="primary" style={{ background: '#9C1FFF'}} onClick={()=>{
      navigator.clipboard.writeText(window.location.origin + "/student/assignment/" + record.id)      
      toast.success("Copied Share Link to Clipboard!")
     }}>
        Share
     </Button>
     <Button className="w-full flex text-center justify-center" danger onClick={()=>{
      Modal.confirm({
        title: 'Are you sure you want to delete this assignment? You will delete all associated conversations to this assignment.',
        onOk: async () => {
          await deleteAssignment(record.id)
          props.history.push('/teacher')
          toast.success("Assignment deleted!")
          setAssignments(assignments.filter(assignment => assignment.id !== record.id));
          
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }}>Delete</Button>
     </div>
    ),
  },
]

  const { userToken } = useContext(SherpaContext)

  useEffect(() => {
    const fetchAssignments = async () => {
      if (userToken) {
      getAllAssignments()
        .then(response => {
          setAssignments(response);
        })
        .catch(error => {
          console.error('Error fetching assignments:', error);
        });
    }
    }
    fetchAssignments();
  }, [userToken]);

  return (
     <>
      <div className="bg-white w-full flex justify-between items-center" style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
        <div className="text-md m-4" style={{
          fontWeight: 600
        }}>Your Assignments</div>
        <Button type="primary" style={{ background: '#9C1FFF'}} className="text-md m-4 flex flex-row items-center gap-2" onClick={()=>{
          props.history.push('/teacher/create')
        }}><AiOutlinePlus color="white"/><span>New Assignment</span></Button>
      </div>
      <div className="w-full flex justify-center">
      {assignments ? 
      <div className="rounded-lg bg-white w-full m-8" style={{border: '1.5px solid #E2E8F0'}} >
        <Table columns={COLUMNS} dataSource={assignments} />
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default TeacherHome;

