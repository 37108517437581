import react from 'react'
import { Modal } from 'antd';


const AllowPermissionsModal = ({
    isOpen,
    setIsOpen,
    onComplete,
    onDecline,
    withVideo
}) => {
    const permRequest = withVideo ? { audio: true, video: true } : { audio: true }
    return (
        <Modal className="allow_permissions_modal" okText="Allow Permissions" title={withVideo ? "Allow Permissons to Microphone and Camera" : "Allow Permissons to Microphone"} open={isOpen} onOk={()=>{
                navigator.mediaDevices.getUserMedia(permRequest)
                .then(function(stream) {
                    onComplete()
                    setIsOpen(false)  
                })
                .catch(function(err) {
                    onDecline()
                });
            }} onCancel={() => {
                setIsOpen(false)
            }}>
                <div>You will now have a conversation with Sherpa, which will require permissions to your microphone. {withVideo ? <>This experience will also require your camera as well.</> : null }</div><br/>
            </Modal>
    )
}

export default AllowPermissionsModal