import SherpaClient from "./main";

// Run evaluation algorithm
export const evaluateConversationAsync = (conversationId, ltik) => {
  const responsePromise = SherpaClient.post(
    "/evaluate/conversation/" + conversationId + (ltik ? "/" + ltik : "/null")
  );
  return responsePromise.then((response) => response.data);
};

export const evaluateConversation = async (conversationId) => {
  const response = await SherpaClient.post(
    "/evaluate/conversation/" + conversationId
  );
  return response.data;
};

export const updateEval = async (evaluationId, updatedData) => {
  const response = await SherpaClient.put(
    "/evaluate/" + evaluationId,
    updatedData
  );
  return response.data;
};
