import React, {useContext, useEffect, useState} from 'react';
import { getAllAssignments } from '../api/assignment'
import ReactLoading from "react-loading";
import { Table, Card, Button } from 'antd'
import { AiOutlinePlus } from "react-icons/ai"

import { SherpaContext } from '../utils/sherpaContext';


const TeacherEvaluate = (props) => {
  const COLUMNS = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (timestamp) => {
      const dateObject = new Date(timestamp);
      const humanDateFormat = dateObject.toLocaleString(); // convert timestamp to readable date
      return humanDateFormat;
    }
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
        <Button onClick={()=>{
        props.history.push('/teacher/evaluate/' + record.id)          
     }}>
        See Students
     </Button>
    ),
  }
]

  const [assignments, setAssignments] = useState(null);

  const { userToken } = useContext(SherpaContext)

  useEffect(() => {
    if (userToken) {
      getAllAssignments()
        .then(response => {
          setAssignments(response);
        })
        .catch(error => {
          console.error('Error fetching assignments:', error);
        });
    }
  }, [userToken]);

  return (
     <>
      <div className="bg-white w-full flex justify-between items-center" style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
        <div className="text-md m-4" style={{
          fontWeight: 600
        }}>Evaluate Your Students</div>
        <Button type="primary" style={{ background: '#9C1FFF'}} className="text-md m-4 flex flex-row items-center gap-2" onClick={()=>{
          props.history.push('/teacher/create')
        }}><AiOutlinePlus color="white"/><span>New Conversation</span></Button>
      </div>
      <div className="w-full flex pt-8 justify-center">
      {assignments ? 
      <div className="rounded-lg bg-white mb-8 w-[800px]" style={{border: '1.5px solid #E2E8F0'}} >
        <Table columns={COLUMNS} dataSource={assignments} />
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default TeacherEvaluate;

