import react, { useContext, useEffect, useState } from 'react'
import { SherpaContext } from '../utils/sherpaContext';
import { Card } from 'antd'
import ReactLoading from 'react-loading'
import { getRole } from '../utils/lti';

const Launch = (props) => {
    const { ltiToken } = useContext(SherpaContext)
    const [isLoading, setIsLoading] = useState(true)
    
    const urlParams = new URLSearchParams(window.location.search);
    const assignmentid = urlParams.get('resourceid');
    const ltik = urlParams.get('ltik');

    useEffect(()=>{
      const role = getRole(ltiToken.token.user.roles)
      // alert(JSON.stringify(ltiToken.token))
      if (role === "teacher"){
        props.history.push("/teacher/assignment/" + assignmentid + "/?ltik=" + ltik)
      } else {
        props.history.push("/student/assignment/" + assignmentid + "/?ltik=" + ltik)
      }
      setIsLoading(false)
    }, [])

  return <div className="flex items-center justify-center w-full h-screen">
        <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
    </div>
}

export default Launch;

// !isLoading ? (
//     <div className="w-full flex h-screen justify-center items-center">
//         <Card className="w-2/3 border-0 flex flex-col gap-4">
//             <div className="w-full flex items-center justify-center text-center flex-col">
//                 <img src={Logo} className="w-32 my-8"/>
//             </div>
//             <Card className="w-full mb-4 hover:bg-[#f9f9f9] cursor-pointer" onClick={()=>{
//               props.history.push('/teacher/assignment/' + assignmentid)
//             }}>
//               View / Edit Assignment
//             </Card>
//             <Card className="w-full mb-4 hover:bg-[#f9f9f9] cursor-pointer" onClick={()=>{
//               props.history.push('/teacher/evaluate/' + assignmentid)
//             }}>
//               See Student Submissions
//             </Card>
//             <Card className="w-full hover:bg-[#f9f9f9] cursor-pointer">
//               Preview
//             </Card>
//         </Card>
//     </div>
//   ) : 