import React, { useContext, useEffect, useState } from 'react';
import {  signInWithEmailAndPassword, signInWithCustomToken   } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { Button, Form, Input, Card, Alert } from 'antd' 
import { toast } from 'react-toastify';
import { SherpaContext } from '../utils/sherpaContext';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { FcGoogle } from "react-icons/fc"
import ReactLoading from 'react-loading'
import { getUser } from '../api/user';
import { AiOutlineArrowLeft } from "react-icons/ai"
import { getCustomToken } from '../api/user';
//import ForgotPassword from './general/ForgotPassword';

const provider = new GoogleAuthProvider();

const Login = (props) => {

    const [loginForm] = Form.useForm();
    const { setUserToken, userToken } = useContext(SherpaContext)

    const queryParams = new URLSearchParams(window.location.search);
    const assignmentid = queryParams.get('assignmentid');

    useEffect(() => {
        
        if (userToken) {
            if (userToken.details.role === "student"){
                if (assignmentid) {
                    props.history.push('/student/assignment/' + assignmentid)    
                } else {
                    props.history.push('/student')
                }
            } else if (userToken.details.role === "teacher") {
                if (assignmentid) {
                    props.history.push('/student/assignment/' + assignmentid)    
                } else {
                    props.history.push('/teacher/gettingstarted')
                }
            } else {
                props.history.push('/onboarding' + (assignmentid ? `?assignmentid=${assignmentid}` : ''))
            }
        }
    }, [userToken])

    const onLogin = async (values) => {
        
        try {
            const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
            setToken(userCredential.user)
        } catch (error) {
            const errorMessage = error.message;
            toast.error(errorMessage);
        }
    }

    const setToken = async (user) => {
        const user_details = await getUser();
        setUserToken({
            user,
            details: user_details
        });
    }
 
    return(
        <div className="flex w-full h-screen justify-center items-center">
            
                {userToken !== undefined ? 
                <Card className="w-[500px] border-0">
                    {!assignmentid ? 
                    <>
                    <div 
                        onClick={()=>{
                            window.location.href = "https://sherpalabs.co"
                        }}
                        className="text-purple-500 flex items-center self-start gap-2 cursor-pointer"
                        style={{
                            fontWeight: '500'
                        }}
                    >
                        <AiOutlineArrowLeft />
                        <span>Back Home</span>
                    </div>
                    </>
                    : null}
                    
                    <div className="text-lg text-left mb-8 mt-4 font-bold">Login to your existing account</div>
                    
                    <Form
                        form={loginForm}
                        layout="vertical"
                        className="w-full"
                        onFinish={onLogin}
                    >
                        <Button
                            className="w-full mb-8 flex justify-center items-center gap-2 font-bold py-6"
                            onClick={async () => {
                                setUserToken(undefined); // trigger loading

                                try {
                                    const result = await signInWithPopup(auth, provider);
                                    setToken(result.user);
                                } catch (error) {
                                    const errorMessage = error.message;
                                    toast.error(errorMessage);
                                }
                            }}

                        ><FcGoogle size={25}/> Login with Google</Button>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                            {
                                required: true,
                                message: "Your email can't be empty!",
                            },
                            ]}
                        >
                            <Input name="username"/>
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                            {
                                required: true,
                                message: "Your password can't be empty!",
                            },
                            ]}
                        >
                            <Input.Password name="password"/>
                        </Form.Item>
                        <Button 
                            htmlType='submit'
                            type="primary"
                            className="w-full text-center"
                            style={{ background: '#9C1FFF'}}
                        >
                            Log In
                        </Button>
                        <div className="mt-8 flex gap-2">
                            <span className="opacity-60">Don't have an account yet?</span>
                            <a className="font-bold text-purple-500 hover:text-purple-300" onClick={()=>{
                                props.history.push('/signup' + (assignmentid ? `?assignmentid=${assignmentid}` : ''))
                            }}>Sign up</a>
                        </div>
                        <div className="mt-4 flex justify-center">
                            <a className="font-bold text-purple-500 hover:text-purple-300" onClick={()=>{
                                props.history.push('/forgotpassword' + (assignmentid ? `?assignmentid=${assignmentid}` : ''))
                            }}>Forgot password?</a>
                        </div>
                    </Form>
                </Card>
                : <ReactLoading
                    type={"spin"}
                    color={"purple"}
                    height={30}
                    width={30}
                    />
                }
        </div>
    )
}
 
export default Login