export const convertUtcToNow = (utc_time) => {
  const dateObject = new Date(utc_time);
  dateObject.setMinutes(
    dateObject.getMinutes() - dateObject.getTimezoneOffset()
  ); // convert UTC to local time
  return dateObject;
};

export const convertMinutesToReadableTime = (minutes) => {
  let days = Math.floor(minutes / 1440);
  minutes -= days * 1440;
  let hours = Math.floor(minutes / 60);
  minutes -= hours * 60;

  let result = "";
  if (days > 0) result += `${days} day${days > 1 ? "s" : ""} `;
  if (hours > 0) result += `${hours} hour${hours > 1 ? "s" : ""} `;
  if (minutes > 0) result += `${minutes} minute${minutes > 1 ? "s" : ""}`;

  return result.trim();
};

export const convertTimeToTimeAgo = (time) => {
  const now = new Date();
  const secondsPast = (now.getTime() - time.getTime()) / 1000;
  if (secondsPast < 60) {
    return parseInt(secondsPast) + " seconds ago";
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + " minutes ago";
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + " hours ago";
  }
  if (secondsPast > 86400) {
    return parseInt(secondsPast / 86400) + " days ago";
  }
};

export const convertTimeToFormat = (time) => {
  let options = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  try {
    return new Intl.DateTimeFormat("en-US", options).format(time);
  } catch (error) {
    console.error("Invalid time value", error);
    return false;
  }
};

export const limitLength = (text, count = 100) => {
  if (text?.length > count) {
    return text.substring(0, count) + "...";
  } else {
    return text;
  }
};
