import React, {useEffect, useState} from 'react';
import ReactLoading from "react-loading";
import { Button, Tag, Select, Table, Statistic } from 'antd'
import { getStudents } from '../api/admin';
import InfoModal from '../components/InfoModal'
import { convertUtcToNow, convertTimeToTimeAgo } from '../utils/general';


const StudentDashboard = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [topSchools, setTopSchools] = useState(null)
  const COLUMNS = [
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        if (created_at) {
          created_at = convertUtcToNow(created_at);
          return convertTimeToTimeAgo(created_at)
        } else {
          return ""
        }
      }
    },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Assignments',
    dataIndex: 'assignments',
    key: 'assignments',
    render: (assignments) => {
      if (assignments.length === 0 ) {
        return <Tag className="w-full text-center" color="red">0</Tag>
      } else if (assignments.length > 0 && assignments.length < 3) {
        return <Tag className="w-full text-center" color="orange">{assignments.length}</Tag>
      } else {
        return <Tag className="w-full text-center" color="green">{assignments.length}</Tag>
      }
    }
  },
  {
    title: 'Conversations',
    dataIndex: 'conversations',
    key: 'conversations',
    render: (conversations) => {
      if (conversations.length === 0 ) {
        return <Tag className="w-full text-center" color="red">0</Tag>
      } else if (conversations.length > 0 && conversations.length < 3) {
        return <Tag className="w-full text-center" color="orange">{conversations.length}</Tag>
      } else {
        return <Tag className="w-full text-center" color="green">{conversations.length}</Tag>
      }
    }
  },
  {
    title: 'Bug Reports',
    dataIndex: 'bug_reports',
    key: 'bug_reports',
    render: (bugreports) => {
      if (bugreports.length === 0 ) {
        return <Tag className="w-full text-center" color="red">0</Tag>
      } else if (bugreports.length > 0 && bugreports.length < 3) {
        return <Tag className="w-full text-center" color="orange">{bugreports.length}</Tag>
      } else {
        return <Tag className="w-full text-center" color="green">{bugreports.length}</Tag>
      }
    }
  },
]
  
  const [students, setStudents] = useState(null);
  const [currentTeacherInfo, setCurrentTeacherInfo] = useState(null)
  const [teacherInfoModal, setStudentsInfoModal] = useState(false)

  useEffect(() => {
    const fetchStudents = async () => {
        setIsLoading(true)
        getStudents(props.weeksAgo)
            .then(response => {
              let domains = {}
              for (let i = 0; i < response.length; i ++) {
                let domain = response[i]["email"].split("@")[1]

                if (Object.keys(domains).includes(domain)) {
                  domains[domain] += 1
                } else {
                  domains[domain] = 1
                }
              }

              let sortedDomains = Object.entries(domains).sort((a, b) => b[1] - a[1]);
              
                setTopSchools(sortedDomains)
                setStudents(response.reverse());
                setIsLoading(false)
            })
            .catch(error => {
                console.error('Error fetching assignments:', error);
            });
    }

    if (props.weeksAgo) {
      fetchStudents();
    }
  }, [props.weeksAgo]);

  return (
     <>
    {currentTeacherInfo ? 
    <InfoModal 
        isOpen={teacherInfoModal}
        setIsOpen={setStudentsInfoModal}
        title="Teacher Info"
        main={<div>
            <h2>Status</h2>
            <b>Getting Started:</b>{JSON.stringify(currentTeacherInfo.getting_started)}<br />
            <b>Intro Status: </b>{JSON.stringify(currentTeacherInfo.intro_status)}<br/>
            <h2>{currentTeacherInfo.conversations.length} Conversations</h2>
            <Select onChange={(value)=>{
                props.history.push('/teacher/conversation/' + value)
            }} className="w-full" placeholder="Click on a conversation">
                {currentTeacherInfo.conversations.map((conversation, i) => {
                    return <Select.Option value={conversation._id} >
                        Conversation {i+1}
                    </Select.Option>
                })}
            </Select>

            <h2>Bug Reports</h2>
            <div className="flex gap-2 flex-col h-96 overflow-scroll">
            {currentTeacherInfo.bug_reports.map(bugreport => {
                return (
                    <div className="rounded-lg p-4" style={{border: '1.5px solid #E2E8F0'}}>
                        <span className="font-semibold text-lg">{bugreport?.bug_metadata?.source ? bugreport?.bug_metadata?.source : null}</span><br/>
                        <span>{bugreport?.current_time}</span><br/>
                        <span>{bugreport?.bug_error_message ? JSON.stringify(bugreport?.bug_error_message) : null}</span><br/>
                        <span>{bugreport?.bug_error_message ? JSON.stringify(bugreport?.bug_metadata) : null}</span>
                    </div>
                )
            })}
            </div>
        </div>}
    />: null}
      <div className="w-full flex flex-col justify-center">
      {!isLoading && students ? 
      <div className="m-8 flex flex-col gap-8">
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <div className="p-8 flex justify-between items-center">
            <Statistic title="Number of Students" value={students.filter(teacher => teacher.role === "student").length} />
            
            <Statistic title="Number of Conversations" value={
            students.reduce((total, teacher) => total + teacher.conversations.length, 0)
            } />
            <Statistic title="Number of Bug Reports" value={
            students.reduce((total, teacher) => total + teacher.bug_reports.length, 0)
            } />
             <div>
                {topSchools.map(school => (
                  <div>
                    {school[0]} : {school[1]}
                  </div>
                ))}
            </div>
        </div>
      </div>
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <Table 
        pagination={{ pageSize: 100 }} 
        onRow={(record)=>{
            return {
                onClick: (e) => {
                  setCurrentTeacherInfo(record)
                  setStudentsInfoModal(true)
                }
            }
        }} columns={COLUMNS} dataSource={students} />
      </div>
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default StudentDashboard;

