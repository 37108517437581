import React, {useEffect, useState} from 'react';
import ReactLoading from "react-loading";
import { Button, Tag, Select, Table, Statistic } from 'antd'
import { getConversations } from '../api/admin';
import InfoModal from '../components/InfoModal'
import { convertUtcToNow, convertTimeToTimeAgo } from '../utils/general';


const ConversationDashboard = (props) => {


  const COLUMNS = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at) => {
      created_at = convertUtcToNow(created_at);
      return convertTimeToTimeAgo(created_at)
    }
  },
  {
    title: 'User',
    dataIndex: 'student',
    key: 'student',
    render: (student) => {
      return student.name + " (" + student?.email + ")"
    }
  },
  {
    title: 'Is Complete',
    dataIndex: 'is_complete',
    key: 'is_complete',
    render: (is_complete) => {
      return is_complete ? <Tag color="green">Yes!</Tag> : <Tag color="red">No :(</Tag>
    }
  },
  {
    title: 'With Video',
    dataIndex: 'with_video',
    key: 'with_video',
    render: (with_video) => {
      return with_video ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
    }
  },
  
  {
    title: 'Role',
    dataIndex: 'student',
    key: 'student',
    render: (student) => {
      return student?.role
    }
  },
  {
    title: 'Assignment',
    dataIndex: 'assignment_id',
    key: 'assignment_id',
    render: (assignment_id) => {
      return <Button type="dashed" onClick={()=>{
        props.history.push('/teacher/assignment/' + assignment_id)
      }}>View Assignment</Button>
    }
  },
  {
    title: 'View Conversation',
    dataIndex: '_id',
    key: '_id',
    render: (id) => {
      return <Button type="primary" onClick={()=>{
        props.history.push('/teacher/conversation/' + id)
      }}>View Conversation</Button>
    }
  },
  {
    title: "No. Past Convos",
    dataIndex: 'past_convos',
    key: 'past_convos',
    render: (past_convos) => {
      const num = (past_convos[0]['count'] - 1)
      return num === 0 ? <Tag color="green">First Conversation!</Tag> : num
    }
  }
  // {
  //   title: 'Conversation',
  //   dataIndex: 'feedback',
  //   key: 'feedback',
  //   render: (feedback) => {
  //     return feedback.map(item => item?.bug_error_message).join("\n") + "\n" + feedback.map(item => item?.bug_metadata?.classroom_breakin).join("\n")
  //   }
  // },
  // {
  //   title: 'First Convo',
  //   dataIndex: 'first_conversation',
  //   key: 'first_conversation',
  //   render: (first_conversation) => {
  //     return first_conversation
  //   }
  // },
  // {
  //   title: 'Evaluation',
  //   dataIndex: 'evaluation_feedback',
  //   key: 'evaluation_feedback',
  //   render: (evaluation_feedback) => {
  //     return evaluation_feedback.map(item => item?.bug_error_message).join("\n") + "\n" + evaluation_feedback.map(item => item?.bug_metadata?.useful).join("\n")
  //   }
  // },
]
const [isLoading, setIsLoading] = useState(true)
  const [conversations, setConversations] = useState(null);
  const [topSchools, setTopSchools] = useState(null)
  const [uniqueStudents, setUniqueStudents] = useState(null)
  const [recurringStudents, setRecurringStudents] = useState(null)

  useEffect(() => {
    const fetchConversations = async () => {
        setIsLoading(true)
        getConversations(props.weeksAgo)
            .then(convos => {
              console.log(convos)
              
              let domains = {}
              let students = new Set()
              let recurring_students = new Set()
              for (let i = 0; i < convos.length; i ++) {
                let domain = convos[i]["student"]["email"].split("@")[1]

                if (Object.keys(domains).includes(domain)) {
                  domains[domain] += 1
                } else {
                  domains[domain] = 1
                }

                students.add(convos[i]["student"]["email"])

                if (convos[i]["past_convos"]?.[0]["count"] > 1) {
                  recurring_students.add(convos[i]["student"]["email"])
                }
              }

              let sortedDomains = Object.entries(domains).sort((a, b) => b[1] - a[1]);
              
              setTopSchools(sortedDomains)
              setUniqueStudents(students)
              setRecurringStudents(recurring_students)
              setConversations(convos);
              setIsLoading(false)
            })
            
            .catch(error => {
                console.error('Error fetching conversations:', error);
            });
    }

    if (props.weeksAgo) {
      fetchConversations();
    }

  }, [props.weeksAgo]);
  return (
     <>
      <div className="w-full flex flex-col justify-center">
      {!isLoading && conversations? 
      <div className="m-8 flex flex-col gap-8">
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <div className="p-8 flex justify-between items-center">
            <Statistic title="Student Conversations" value={conversations.filter(item => item.student.role === "student").length} />
            <Statistic title="Unique Students" value={uniqueStudents?.size} />
            <Statistic title="Recurring Users!" value={recurringStudents?.size} />
            <Statistic title="% Conversations Complete" value={Math.round((conversations.filter(convo => convo.is_complete === true).length / conversations.length)*100).toString() + "%"} />
            <div>
                {topSchools.map(school => (
                  <div>
                    {school[0]} : {school[1]}
                  </div>
                ))}
            </div>
        </div>
      </div>
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <Table columns={COLUMNS} dataSource={conversations} />
      </div>
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default ConversationDashboard;

