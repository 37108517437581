import SherpaClient from "./main";

export const reportBug = async (message, source, metadata=null) => {
  const current_url = window.location.href
  const info = {
    ...metadata,
    source
  }
  const response = await SherpaClient.post(`/bugreports`, {
    "bug_error_message": message,
    "url": current_url,
    "bug_metadata": info
  });
  return response.data
}
