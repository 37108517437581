import React, {useEffect, useState} from "react"
import { Modal, Form, Input, Select, Button } from "antd"
import { reportBug } from "../api/bugreports"
import { toast } from "react-toastify"


const BugReportModal = ({userToken, isOpen, source = "Default", setIsOpen, type="Bug Report or Feature Requests", customForm, onComplete, customOnSubmit=null, initialValues={}}) => {

    const [mainForm] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        mainForm.setFieldsValue(initialValues);
    }, [initialValues, mainForm]);

    return (
        <Modal style={{
            zIndex: 999999999999999999999

        }} title={type}open={isOpen} footer={[
        ]} onCancel={() => {
          setIsOpen(false)
        }}>
            {source === "Survey" ? 
            <>
                <p className="text-gray-500">This form will <b>no longer re-appear after you complete it</b>. We'd love to learn more about your experience!</p> 
                <p className="text-gray-500">All data collected will be used purely to improve Sherpa, and will be kept <b>strictly private</b>, so please be as <b>honest as possible</b>.</p>
            </>
            : <br />}
            <Form
                form = {mainForm}
                onFinish={async (values) => {
                    if (!customOnSubmit) {
                        const { details, ...rest } = values;
                        setIsSubmitting(true)
                        await reportBug(values.details, source, rest)

                        toast.success("Thanks for your feedback!")

                        if (onComplete) {
                            onComplete(values);
                        }
                        setIsSubmitting(false)
                    } else {
                        customOnSubmit(values)
                    }
                    mainForm.resetFields()
                    setIsOpen(false)
                }}
                layout="vertical"
                initialValues={initialValues}
            >
                {customForm ? 
                <>
                    {customForm}
                </>
                : 
                <>
                    <Form.Item
                        label="What happened?"
                        name="type"
                        rules={[{ required: true, message: 'This is required!'}]}
                    >
                        <Select>
                            <Select.Option value={"bug"}>Something went wrong.</Select.Option>
                            <Select.Option value={"feature_request"}>I want to request a new feature.</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="details"
                        label="Please tell us more details?"
                        rules={[{ required: true, message: 'This is required!'}]}
                    >
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                </>
                }
                <div className="w-full justify-end flex">
                    <Button disabled={isSubmitting} htmlType="submit" type="primary" style={{
                        background: '#9C1FFF'
                    }}>
                        Submit
                    </Button>
                </div>
            </Form>
          </Modal>
    )
}

export default BugReportModal