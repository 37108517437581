
import React, { useEffect, useContext } from 'react';
import { Button } from 'antd';
import {  sendEmailVerification  } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { toast } from 'react-toastify';
import { SherpaContext } from '../utils/sherpaContext';

const NotVerified = (props) => {

    const { userToken } = useContext(SherpaContext)

    useEffect(()=>{
        if (userToken.user.emailVerified) {
            props.history.push('/login')
        }
    }, [])
    
    return (
        <>
            <div className="flex items-center justify-center w-full flex-col h-screen gap-8">
                You have not verified your email!

                <Button
                    onClick={()=>{
                        sendEmailVerification(auth.currentUser)
                        .then(() => {
                            toast.success('Email verification sent!')
                        });
                    }}
                >
                    Re-Send Verification Email
                </Button>
            </div>
        </>
    );
}

export default NotVerified



