import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SherpaProvider } from "./utils/sherpaContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "intro.js/introjs.css";
import { ConfigProvider } from "antd";
import amplitude from "amplitude-js";

// Only for this for production
if (process.env.NODE_ENV === "production") {
  var instance = amplitude
    .getInstance()
    .init("267f2a3a2b6ead2188a320700bf5c2ce");

  amplitude.getInstance().logEvent("App Viewed");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: "#9C1FFF",
      },
    }}
  >
    <SherpaProvider>
      <ToastContainer />
      <App />
    </SherpaProvider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
