import React, { useEffect, useState } from "react";
import { lti_setupDeepLink } from "../api/lti";
import { auth } from './firebase'
import { getUser } from "../api/user";
import { RaceBy } from '@uiball/loaders'
import Logo from '../img/logo_black_text.svg'
import { signInWithCustomToken } from "firebase/auth"
import { updateUser } from "../api/user";
import amplitude from "amplitude-js";
import { Crisp } from "crisp-sdk-web";

const defaultData = {
    userToken: undefined,
    setUserToken: () => {},
    ltiToken: undefined,
    setLTIToken: () => {},
}

export const SherpaContext = React.createContext(defaultData);

export const SherpaProvider = ({ children }) => {
    const [userToken, setUserToken] = useState(defaultData.userToken)
    const [ltiToken, setLTIToken] = useState(defaultData.ltiToken)

    const updateIntroStatus = async (type) => {
        const updatedUserToken = {
            "intro_status": {
                ...userToken.details.intro_status,
                [type]: true
            }
        }
        await updateUser(updatedUserToken)
        setUserToken({
            ...userToken, 
            "details": {
                ...userToken.details,
                ...updatedUserToken,
            }  
        })
    }

    const updateGettingStartedStatus = async (type, value=true) => {
        const updatedUserToken = {
            "getting_started": {
                ...userToken.details.getting_started,
                [type]: value
            }
        }
        await updateUser(updatedUserToken)
        setUserToken({
            ...userToken, 
            "details": {
                ...userToken.details,
                ...updatedUserToken
            }
            
        })
    }

    useEffect(()=>{
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                const user_details = await getUser()
                setUserToken({
                    "user": user,
                    "details": user_details
                })
                if (process.env.NODE_ENV === "production") {
                    amplitude.getInstance().logEvent("Logged In");
                }
                console.log("logged in")
            } else {
                setUserToken(null)
                console.log("logged out")
            }
        });

        const LTIAuthCheck = async (ltik) => {
            const data = await lti_setupDeepLink(ltik)
            setLTIToken({
                "token": data.lti,
                "ltik": ltik
            })
            if (data?.status === "success") {
                await signInWithCustomToken(auth, data.custom_token) // this will trigger the login flow! 
            } 
        }
        // Check for LTI
        const urlParams = new URLSearchParams(window.location.search);
        const ltik = urlParams.get('ltik');

        if (ltik) {
            LTIAuthCheck(ltik)
        } else {
            setLTIToken(null)
        }
        
    }, [])

    useEffect(()=>{
        if (userToken) {
            if (process.env.NODE_ENV === "production") {
                amplitude.getInstance().setUserId(userToken.user.uid);
                amplitude.getInstance().setUserProperties(userToken.details);   
            }
            
            if (userToken.details.role === "teacher") {
                Crisp.configure("1722d6a5-3043-4280-9358-603beaf32382");
            }
        }
    }, [userToken])

    // TODO: Fix LTI token thingy.
    return userToken !== undefined && ltiToken !== undefined ? (
        <SherpaContext.Provider
            value={{
                userToken, // null means logged out, exists means logged in
                ltiToken,
                setUserToken,
                setLTIToken,
                updateIntroStatus,
                updateGettingStartedStatus
            }}
        >
            {children}
        </SherpaContext.Provider>
    ) : <div className="flex items-center justify-center w-full h-screen flex-col gap-8">
        <img src={Logo} className="w-32" />
        <RaceBy 
            size={120}
            lineWeight={5}
            speed={1.4} 
            color="#a68bfa" 
        />
    </div>
}
