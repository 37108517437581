import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TeacherAssignment from "./teacher/TeacherAssignment";
import TeacherAssignmentQuestions from "./teacher/TeacherAssignmentQuestions";
import TeacherAssignmentEvaluation from "./teacher/TeacherAssignmentEvaluation";
import TeacherConversation from "./teacher/TeacherConversation";
import StudentMain from "./student/StudentMain";
import AssignmentConversation from "./student/AssignmentConversation";
import TeacherHome from "./teacher/TeacherHome";
import TeacherCreateAssignment from "./teacher/TeacherCreate";
import Signup from "./general/Signup";
import Login from "./general/Login";
import Onboarding from "./general/Onboarding";
import Authenticated from "./components/Authenticated";
import TeacherEvaluate from "./teacher/TeacherEvaluate";
import NotVerified from "./general/NotVerified";
import StudentHome from "./student/StudentHome";
import AccessDenied from "./general/AccessDenied";
import DeepLink from "./lti/DeepLinkPage";
import Launch from "./lti/Launch";
import GettingStarted from "./teacher/GettingStarted";
import TeacherDashboard from "./admin/TeacherDashboard";
import ConversationDashboard from "./admin/ConversationDashboard";
import EvaluationDashboard from "./admin/EvaluationDashboard";
import AssignmentDashboard from "./admin/AssignmentDashboard";
import TeacherSubmissions from "./teacher/TeacherSubmissions";
import TeacherSubmissionsDemo from "./teacher/TeacherSubmissionsDemo";
import SimulatedLogin from "./general/SimulatedLogin";
import StudentDashboard from "./admin/StudentDashboard";
import BugReportDashboard from "./admin/BugReportDashboard";
import AdminMain from "./admin/AdminMain";
import ForgotPassword from "./general/ForgotPassword";
import Survey from "./general/Survey";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={(props) => <Login {...props} />} />
        <Route
          exact
          path="/accessdenied"
          render={(props) => (
            <Authenticated {...props} mobileOnly={false} showSidebar={false}>
              <AccessDenied {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/signup"
          mobileOnly={false}
          render={(props) => <Signup {...props} />}
        />
        <Route
          exact
          path="/login"
          mobileOnly={false}
          render={(props) => <Login {...props} />}
        />
        <Route
          exact
          path="/forgotpassword"
          mobileOnly={false}
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          exact
          path="/admin/login"
          mobileOnly={false}
          render={(props) => <SimulatedLogin {...props} />}
        />
        <Route
          exact
          path="/admin/teachers"
          render={(props) => (
            <Authenticated {...props} showSidebar={true} forAdminOnly={true}>
              <AdminMain {...props} type="teachers">
                <TeacherDashboard />
              </AdminMain>
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/admin/bugreports"
          render={(props) => (
            <Authenticated {...props} showSidebar={true} forAdminOnly={true}>
              <AdminMain {...props} type="bugreports">
                <BugReportDashboard />
              </AdminMain>
            </Authenticated>
          )}
        />

        <Route
          exact
          path="/admin/students"
          render={(props) => (
            <Authenticated {...props} showSidebar={true} forAdminOnly={true}>
              <AdminMain {...props} type="students">
                <StudentDashboard />
              </AdminMain>
            </Authenticated>
          )}
        />

        <Route
          exact
          path="/admin/conversations"
          render={(props) => (
            <Authenticated {...props} showSidebar={true} forAdminOnly={true}>
              <AdminMain {...props} type="conversations">
                <ConversationDashboard />
              </AdminMain>
            </Authenticated>
          )}
        />

        <Route
          exact
          path="/admin/assignments"
          render={(props) => (
            <Authenticated {...props} showSidebar={true} forAdminOnly={true}>
              <AdminMain {...props} type="assignments">
                <AssignmentDashboard />
              </AdminMain>
            </Authenticated>
          )}
        />

        <Route
          exact
          path="/admin/evaluations"
          render={(props) => (
            <Authenticated {...props} showSidebar={true} forAdminOnly={true}>
              <AdminMain {...props} type="evaluations">
                <EvaluationDashboard />
              </AdminMain>
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/gettingstarted"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]} showSidebar={true}>
              <GettingStarted {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/onboarding"
          render={(props) => (
            <Authenticated {...props} mobileOnly={false} showSidebar={false}>
              <Onboarding {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/survey"
          render={(props) => (
            <Authenticated {...props} mobileOnly={false} showSidebar={false}>
              <Survey {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/notVerified"
          render={(props) => (
            <Authenticated {...props} mobileOnly={false} showSidebar={false}>
              <NotVerified {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/student"
          render={(props) => (
            <Authenticated {...props} showSidebar={false} showHome={true}>
              <StudentHome {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/student/assignment/:assignmentid"
          render={(props) => (
            <Authenticated {...props} showSidebar={false} showHome={false}>
              <AssignmentConversation {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/student/conversation/:conversationid"
          render={(props) => (
            <Authenticated {...props} showSidebar={false} showHome={false}>
              <StudentMain {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherHome {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/submissions"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherSubmissions {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/submissions/demo"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherSubmissionsDemo {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/evaluate"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherEvaluate {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/evaluate/:assignmentid"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherAssignmentEvaluation {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/create"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherCreateAssignment {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/assignment/:assignmentid"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherAssignment {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/assignment/:assignmentid/questions"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherAssignmentQuestions {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/teacher/conversation/:conversationid"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]}>
              <TeacherConversation {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/student/evaluate/:conversationid"
          render={(props) => (
            <Authenticated
              {...props}
              forOnly={["student", "teacher"]}
              showSidebar={false}
              showHome={true}
            >
              <TeacherConversation {...props} studentView={true} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/deeplink"
          render={(props) => (
            <Authenticated {...props} forOnly={["teacher"]} showSidebar={false}>
              <DeepLink {...props} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/launch"
          render={(props) => (
            <Authenticated {...props} showSidebar={false}>
              <Launch {...props} />
            </Authenticated>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
