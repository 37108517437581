import SherpaClient from "./main";

// Create Assignment
export const createAssignment = async (assignment_data) => {
  const response = await SherpaClient.post("/assignments", assignment_data);
  return response.data;
};
export const createAssignmentV2 = async (assignment_data) => {
  const response = await SherpaClient.post("/assignments/v2", assignment_data);
  return response.data;
};
export const migrateAssignmentToV2 = async (assignment_id) => {
  const response = await SherpaClient.post(
    "/assignments/" + assignment_id + "/migrate/v2"
  );
  return response.data;
};

// Get all assignments
export const getAllAssignments = async () => {
  const response = await SherpaClient.get("/assignments");
  return response.data;
};

// Get a single assignment
export const getAssignment = async (assignmentid) => {
  const response = await SherpaClient.get("/assignments/" + assignmentid);
  return response.data;
};

// Delete assignment
export const deleteAssignment = async (assignmentid) => {
  const response = await SherpaClient.delete("/assignments/" + assignmentid);
  return response.data;
};

// Update Assignment
export const updateAssignment = async (assignment_id, updated_object) => {
  const response = await SherpaClient.put(
    "/assignments/" + assignment_id,
    updated_object
  );
  return response.data;
};

