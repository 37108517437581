import React, { useEffect, useState} from 'react';
import { getQuestions } from '../api/questions'
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { List, Tag, Switch, Button, Card  } from 'antd'
import { AiOutlineLeft } from 'react-icons/ai';
import InfoModal from '../components/InfoModal';

const QUESTION_TYPES = [
  {
    "name": "Level 1 Questions",
    "key": "base_1",
    "description": "These questions ... "
  },
  {
    "name": "Level 2 Questions",
    "key": "base_2",
    "description": "These questions ... "
  },
  {
    "name": "Level 3 Questions",
    "key": "base_3",
    "description": "These questions ... "
  },
]
const TeacherAssignmentQuestions = (props) => {
  const [questions, setQuestions] = useState(null)
  const [snippets, setSnippets] = useState(null)
  const [snippetsModalIsOpen, setSnippetsModalIsOpen] = useState(false)

  const { assignmentid } = useParams();

  useEffect(()=>{
    const fetchQuestions = async () => {
      const response = await getQuestions(assignmentid)
      console.log(response)
      setQuestions(response)
    }

    if (assignmentid) {
      fetchQuestions()
    }
    
  }, [assignmentid])

  return questions ? (
    <>
    
    <InfoModal 
      isOpen={snippetsModalIsOpen}
      setIsOpen={setSnippetsModalIsOpen}
      title="Relevant Excerpts"
      main={
        <div className="w-full flex flex-col gap-4">
          {snippets && snippets.map(snippet => (
            <Card>{snippet.page_content}</Card>
          ))}
        </div>
      }
    />
     <div className={`bg-white w-full flex justify-between items-center h-16`} style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
      
          <div className="flex items-center cursor-pointer pl-4 gap-2" onClick={()=>{
            props.history.goBack();
          }}>
            <AiOutlineLeft />
            Back
          </div>
      
        
    </div>
      <div className="w-full flex py-16 justify-center flex-col items-center gap-8">
        {QUESTION_TYPES.map((type) => (
          <>
            <div className="rounded-lg bg-white w-[800px] flex justify-between items-center" >
              <h2>{type.name}</h2>
              {/* <Button type="dashed">Generate More Questions</Button> */}
            </div>
            <div className="rounded-lg bg-white w-[800px]" style={{border: '1.5px solid #E2E8F0'}} >
              <div className="px-8 py-6">
                <List
                  itemLayout="horizontal"
                  dataSource={questions.filter(question => question.type === type.key)}
                  renderItem={(item, index) => (
                    <List.Item className="mb-3 flex flex-col w-full">
                      <List.Item.Meta
                        className="w-full"
                        title={<div className="flex justify-between items-center gap-2 mb-3">
                          <b>{item.input}</b>
                          <Switch checked={true} />
                        </div>}
                        description={item.model_answer}
                      />
                      {item.faithfulness_score || item.answer_spread || item.snippets ? 
                      <div className="flex items-center gap-2 my-3 w-full" style={{
                        alignSelf: 'start'
                      }}>
                        <div className="flex justify-between w-full items-center">
                          <div>
                            {item.faithfulness_score ? 
                            <Tag color="blue">{Math.round(item.faithfulness_score * 100)}% Grounded</Tag>
                            : null }
                            {item.answer_spread ? 
                            <Tag color="orange">{Math.round(item.answer_spread)} Spread</Tag>
                            : null }
                          </div>
                          <Button onClick={()=>{
                            setSnippets(item.snippets)
                            setSnippetsModalIsOpen(true)
                          }}>
                            See Relevant Excerpts
                          </Button>
                        </div>
                      </div>
                      : null }
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </>
        ))}
        
      </div>
  </>
  ) :
  <div className="w-full h-full flex items-center justify-center">
    <ReactLoading
      type={"spin"}
      color={"purple"}
      height={30}
      width={30}
    />
  </div>
}

export default TeacherAssignmentQuestions;
