import React, {useEffect, useState} from 'react';
import ReactLoading from "react-loading";
import { Table, Statistic } from 'antd'
import { getBugReports } from '../api/admin';
import { convertUtcToNow, convertTimeToTimeAgo } from '../utils/general';


const BugReportDashboard = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const COLUMNS = [
    {
      title: 'Created At',
      dataIndex: 'current_time',
      key: 'current_time',
      render: (created_at) => {
        if (created_at) {
          created_at = convertUtcToNow(created_at);
          return convertTimeToTimeAgo(created_at)
        } else {
          return ""
        }
      }
    },
  {
    title: 'Error Message',
    dataIndex: 'bug_error_message',
    key: 'bug_error_message',
    render: (bug_error_message) => {
      return bug_error_message
    }
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    render: (url) => {
      return url
    }
  },
  {
    title: 'Email',
    dataIndex: 'user',
    key: 'user',
    render: (user) => {
      return user.email
    }
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (user) => {
      return user.name + " (" + user.email + ")"
    }
  },
  {
    title: 'Type',
    dataIndex: 'bug_metadata',
    key: 'type',
    render: (bug_metadata) => {
      return bug_metadata.source ?? ""
    }
  },

]
  
  const [bugreports, setBugReports] = useState(null);

  useEffect(() => {
    
    const fetchBugReports = async () => {
      setIsLoading(true)
        getBugReports(props.weeksAgo)
            .then(response => {
              setBugReports(response);
              setIsLoading(false)
            })
            .catch(error => {
                console.error('Error fetching Bug Reports:', error);
            });
    }
    if (props.weeksAgo) {
      fetchBugReports();
    }
  }, [props.weeksAgo]);
  return (
     <>

      <div className="w-full flex flex-col justify-center">
      {bugreports && !isLoading ? 
      <div className="m-8 flex flex-col gap-8">
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <div className="p-8 flex justify-between items-center">
            <Statistic title="Number of Bug Reports" value={bugreports.length} />
        </div>
      </div>
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <Table 
          pagination={{ pageSize: 100 }}  
          columns={COLUMNS} 
          dataSource={bugreports} 
        />
      </div>
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default BugReportDashboard;

