import SherpaClient from "./main";

export const processQuestion = async (videoInfo) => {
  const response = await SherpaClient.post(`/videos/process`, videoInfo);
  return response.data
}

export const compileCurrentTranscript = async (conversation) => {
  const response = await SherpaClient.post(`/videos/currentTranscript`, conversation);
  return response.data;
}

