import React, {useContext, useEffect, useState} from 'react';
import { getAllConversations } from '../api/conversation';
import ReactLoading from "react-loading";
import { Table, Card, Button, Tag } from 'antd'
import { AiOutlineLeft } from "react-icons/ai"
import { toast } from 'react-toastify';
import { convertTimeToTimeAgo, convertUtcToNow } from '../utils/general'
import { SherpaContext } from '../utils/sherpaContext';
import { getAllAssignments } from '../api/assignment';


const TeacherSubmissionsDemo = (props) => {
  const [conversations, setConversations] = useState(null);
  const [totalConversations, setTotalConversations] = useState(null);
  const [assignments, setAssignments] = useState(null);

  const queryParams = new URLSearchParams(props.location.search);
  
  const getFilteredAssignments = () => {
    return queryParams.get('assignments')?.split(",")
  }

  const getCurrentPage = () => {
    return queryParams.get('page') ? parseInt(queryParams.get('page')) : 1
  }

  
  const COLUMNS = [
  {
    title: 'Student',
    dataIndex: 'student',
    key: 'student',
    width: '20%',
    render: (student) => {
      return student.name
    }
  },
  {
    title: 'Status',
    dataIndex: 'evals',
    width: '20%',
    // sorter: (a, b) => a.evals.items.filter(item => item.status === "warning").length - b.evals.items.filter(item => item.status === "warning").length,
    render: (evaluations) => {
      return evaluations.items.filter(item => item.status === "warning").length > 0 ? 
        <Tag color="#ff694f" className="status-tag">{evaluations.items.filter(item => item.status === "warning").length} {evaluations.items.filter(item => item.status === "warning").length > 1 ? "warnings" : "warning"}</Tag> 
      : evaluations.items.length > 0 ?
        <Tag color="#87d068" className="status-tag">Verified</Tag> 
      : evaluations.is_complete ? 
        <Tag color="lime" className="status-tag">Completed</Tag>
      : <Tag color="orange" className="status-tag">In Progress</Tag>

    }
  },
  {
    title: 'Assignment',
    dataIndex: 'assignment',
    key: 'assignment',
    width: '50%',
    filters: assignments ? assignments.map(item => {
      return {
        "value": item.id,
        "text": item.title
      }
    }) : [],
    filteredValue: getFilteredAssignments(),
    filterMode: 'single',
    render: (assignment) => {
      return assignment.title
    }
  },
  {
    title: 'Started',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '10%',
    render: (timestamp) => {
      const now = convertUtcToNow(timestamp)
      return convertTimeToTimeAgo(now)
    }
  },
]

  const fetchData = async () => {
    // const conversationsData = await getAllConversations(getFilteredAssignments(), getCurrentPage())
    const conversationsData = {
      "conversations": [
        {
          "_id": "64f610b975253ed31e26e971",
          "with_video": false,
          "created_at": "2023-09-01T17:15:37.642000",
          "assignment": {
            "title": "Sample Reading",
            "id": "64f6109075253ed31e26e96e"
          },
          "evals": {
            "items": [
              {
                "_id": 0,
                "status": "warning",
                "reason": "This response misses crucial elements of the reading, including the purpose of prompt engineering and its role in guiding language models.",
                "action": "Understanding the concept of Prompt Engineering",
                "confidence_score": 9,
                "confidence_reason": "This is because the student's response does not align with the ideal response and lacks key elements of the concept of prompt engineering."
              },
              {
                "_id": 1,
                "status": "warning",
                "reason": "This response misses crucial elements of the reading, including the purpose of prompt engineering and its role in guiding language models.",
                "action": "Understanding the concept of Prompt Engineering",
                "confidence_score": 7,
                "confidence_reason": "This is because the student's response does not align with the ideal response and lacks key elements of the concept of prompt engineering."
              },
              {
                "_id": 2,
                "status": "warning",
                "reason": "This response misses crucial elements of the reading, including the purpose of prompt engineering and its role in guiding language models.",
                "action": "Understanding the concept of Prompt Engineering",
                "confidence_score": 6,
                "confidence_reason": "This is because the student's response does not align with the ideal response and lacks key elements of the concept of prompt engineering."
              },
              {
                "_id": 3,
                "status": "warning",
                "reason": "This response misses crucial elements of the reading, including the purpose of prompt engineering and its role in guiding language models.",
                "action": "Understanding the concept of Prompt Engineering",
                "confidence_score": 5,
                "confidence_reason": "This is because the student's response does not align with the ideal response and lacks key elements of the concept of prompt engineering."
              }
            ],
            "is_evaluated": true,
            "is_complete": true
          },
          "student": {
            "uid": "1cjNuEgBomT84iBOWNJrAuyupAf1",
            "name": "Benjamin Parker",
            "email": "BenParker@gmail.com"
          }
        },
        {
          "_id": "64fa373b49f670360412657b",
          "with_video": true,
          "created_at": "2023-08-31T02:44:31.468000",
          "assignment": {
            "title": "Malleable Software in the Age of LLMs",
            "id": "64ecd985068dc0c2189a8a6a"
          },
          "evals": {
            "items": [
              {
                "_id": 0,
                "status": "warning",
                "reason": "This response misses crucial elements of the reading, including the impact of online schools on traditional education system.",
                "action": "Understanding the concept of Online Education",
                "confidence_score": 8,
                "confidence_reason": "This is because the student's response does not align with the ideal response and lacks key elements of the concept of online education."
              },
              {
                "_id": 1,
                "status": "warning",
                "reason": "This response misses crucial elements of the reading, including the impact of online schools on traditional education system.",
                "action": "Understanding the concept of Online Education",
                "confidence_score": 7,
                "confidence_reason": "This is because the student's response does not align with the ideal response and lacks key elements of the concept of online education."
              }
            ],
            "is_evaluated": true,
            "is_complete": false
          },
          "student": {
            "uid": "Es9o2JDPUsS9bJVB0RFbP2lgQRJ2",
            "name": "Joseph Tey",
            "email": "Andy.simmons@gmail.com"
          }
        },
        {
          "_id": "64ec30857469b78e5a53389c",
          "with_video": false,
          "created_at": "2023-08-29T05:28:37.861000",
          "assignment": {
            "title": "Great Gatsby Reading",
            "id": "64e90773b63d54efe1600706"
          },
          "evals": {
            "items": [
              {
                "_id": 0,
                "status": "warning",
                "reason": "This response misses crucial elements of the reading, including the characterisation and themes presented in The Great Gatsby.",
                "action": "Understanding the analysis of The Great Gatsby",
                "confidence_score": 9,
                "confidence_reason": "This is because the student's response does not align with the ideal response and lacks key elements of the analysis of The Great Gatsby."
              }
            ],
            "is_evaluated": true,
            "is_complete": false
          },
          "student": {
            "uid": "ybR3zbYL69TnSOyvjFlEQBN6P8p1",
            "name": "Martha Rogers",
            "email": "martha.rogers@gmail.com"
          }
        },
        {
          "_id": "75g789j5917788y34325e9t96",
          "with_video": false,
          "created_at": "2023-09-01T10:15:37.642000",
          "assignment": {
            "title": "Mathematics Homework",
            "id": "69f6109075323ed22e26e97f"
          },
          "evals": {
            "items": [],
            "is_evaluated": true,
            "is_complete": true
          },
          "student": {
            "uid": "2TjNxFfBwpE94kUQVSPtB5rp3F12",
            "name": "Oliver Smith",
            "email": "oliver.smith@gmail.com"
          }
        },
        {
          "_id": "75m726r5788478z27215a8s73",
          "with_video": true,
          "created_at": "2023-08-30T15:44:31.468000",
          "assignment": {
            "title": "Science Project",
            "id": "79m6188650324fb19g28j6k6"
          },
          "evals": {
            "items": [],
            "is_complete": true
          },
          "student": {
            "uid": "Yr5o3JEYtRs5aNWY1RJqP4lmQRP1",
            "name": "Emily Johnson",
            "email": "emily.johnson@gmail.com"
          }
        },
        {
          "_id": "84n738t5469291a14195b8u64",
          "with_video": false,
          "created_at": "2023-08-30T08:28:37.861000",
          "assignment": {
            "title": "English Literature Essay",
            "id": "88g81874c79d59h2120z07a1"
          },
          "evals": {
            "items": [],
            "is_complete": true
          },
          "student": {
            "uid": "WbQ4qcNMR8zEcPx4lElfQBw6P8d2",
            "name": "Liam Williams",
            "email": "liam.williams@gmail.com"
          }
        },
        {
          "_id": "96t749w5239494n31325c9v87",
          "with_video": false,
          "created_at": "2023-08-31T10:15:37.642000",
          "assignment": {
            "title": "History Quiz",
            "id": "89e7209175323ed23e26f97f"
          },
          "evals": {
            "items": [],
            "is_evaluated": true,
            "is_complete": true
          },
          "student": {
            "uid": "4HkOxVgAxZT94mEOWNTrA5w2If2",
            "name": "Sophia Wilson",
            "email": "sophia.wilson@gmail.com"
          }
        },
        {
          "_id": "96a750c5249494n53325d9s82",
          "with_video": true,
          "created_at": "2023-08-31T20:01:31.468000",
          "assignment": {
            "title": "Geography Field Trip Report",
            "id": "99m6188650324fb49g28j6b6"
          },
          "evals": {
            "items": [],
            "is_complete": true
          },
          "student": {
            "uid": "Ks7o3JCYhSc5aNKZ1RLqP6pmQSP2",
            "name": "Ava Jones",
            "email": "ava.jones@gmail.com"
          }
        },
        {
          "_id": "84z748t3459291a22985b8u94",
          "with_video": false,
          "created_at": "2023-09-02T08:28:37.861000",
          "assignment": {
            "title": "French Assignment",
            "id": "78g90984c78d49h2120z07a2"
          },
          "evals": {
            "items": [],
            "is_complete": true
          },
          "student": {
            "uid": "DbS6ooYOQ9nAcNw6lElfQBb6P8w3",
            "name": "Jacob Brown",
            "email": "jacob.brown@gmail.com"
          }
        },
        {
          "_id": "96t750w4239499n30335k9v67",
          "with_video": false,
          "created_at": "2023-09-02T22:15:37.642000",
          "assignment": {
            "title": "Art Project",
            "id": "79z7109075323rx43e16b97y"
          },
          "evals": {
            "items": [],
            "is_evaluated": true,
            "is_complete": true
          },
          "student": {
            "uid": "5KiMzFpAxDT94uEOWNTrA3x2Jf5",
            "name": "Isabella Taylor",
            "email": "isabella.taylor@gmail.com"
          }
        },
      ],
      "count": [
        {
          "total_count": 10
        }
      ]
    }
    setConversations(conversationsData.conversations)
    setTotalConversations(conversationsData.count)
    setAssignments(await getAllAssignments())
  }

  useEffect(() => {
    fetchData();
  }, [queryParams.get("assignments"), queryParams.get("page")]);


  return (
     <>
      <div className="bg-white w-full flex justify-between items-center" style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
        {getFilteredAssignments() && <div className="flex items-center cursor-pointer pl-4 gap-2" onClick={()=>{
          props.history.goBack();
        }}>
          <AiOutlineLeft />
          Back
        </div>}
        <div className="text-md m-4" style={{
          fontWeight: 600
        }}>Student Submissions</div>
        <div className="w-32"></div>
      </div>
      <div className="w-full flex justify-center">
      {/* {queryParams.get('assignments') ? 
      <div className="rounded-lg bg-white w-full flex justify-between items-center" style={{border: '1.5px solid #E2E8F0'}} >
        <Statistic title="Number of Students" value={conversations.length} />
        <div className="flex flex-col">
          <Button></Button>
        </div>
      </div>
      : null } */}
      {conversations && assignments ? 
      <div className="rounded-lg bg-white w-full m-8" style={{border: '1.5px solid #E2E8F0'}} >
        <Table style={{
          fontSize: '20px !important'
        }} 

        onRow={(record) => {
          return {
            onClick: event => {
              props.history.push('/teacher/conversation/' + record._id)
            },
            onMouseEnter: event => {
              event.target.style.cursor = 'pointer';
            },
            onMouseLeave: event => {
              event.target.style.cursor = 'pointer';
            },
          };
        }}
        rowClassName={() => 'clickable-row'}
        columns={COLUMNS} dataSource={conversations} onChange={(pagination, filters, sorter, extra) => {
          // update assignment ids
          console.log(filters)
          const assignment_ids = filters.assignment;
          if (assignment_ids) {
            const assignmentsString = assignment_ids.join(',');
            queryParams.set('assignments', assignmentsString);
            props.history.push({search: queryParams.toString()});
          } else {
            queryParams.delete('assignments')
            props.history.push({search: queryParams.toString()});
          }

          // pagination
          if (pagination?.current){
            queryParams.set('page', pagination?.current);
            props.history.push({search: queryParams.toString()});
          }

        }} 
        pagination={{ position: ['bottomLeft'],current: getCurrentPage(), pageSize: 20, total: totalConversations?.[0]?.total_count, showSizeChanger: false }} />
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default TeacherSubmissionsDemo;

