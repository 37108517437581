import React, { useContext, useEffect, useState } from 'react';
import {  signInWithEmailAndPassword, signInWithCustomToken   } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { Button, Form, Input, Card, Alert } from 'antd' 
import { toast } from 'react-toastify';
import { SherpaContext } from '../utils/sherpaContext';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { FcGoogle } from "react-icons/fc"
import ReactLoading from 'react-loading'
import { getUser } from '../api/user';
import { AiOutlineArrowLeft } from "react-icons/ai"
import { getCustomToken } from '../api/user';

const SimulatedLogin = (props) => {

    const [loginForm] = Form.useForm();
    const { setUserToken, userToken } = useContext(SherpaContext)

    useEffect(() => {
        
        if (userToken) {
            if (userToken.details.role === "student"){
                    props.history.push('/student')
            } else if (userToken.details.role === "teacher") {
                    props.history.push('/teacher/gettingstarted')
            } else {
                props.history.push('/onboarding')
            }
        }
    }, [userToken])

    const onLogin = async (values) => {
        
      if (values.password === "joeteyisthebomb"){
        try {
            const customToken = await getCustomToken(values.email);
            const userCredential = await signInWithCustomToken(auth, customToken);
            setToken(userCredential.user)
        } catch (error) {
            const errorMessage = error.message;
            toast.error(errorMessage);
        }
      }
    }

    const setToken = async (user) => {
        const user_details = await getUser();
        setUserToken({
            user,
            details: user_details
        });
    }
 
    return(
        <div className="flex w-full h-screen justify-center items-center">
            
                {userToken !== undefined ? 
                <Card className="w-[500px] border-0">
                    
                    <div className="text-lg text-left mb-8 mt-4 font-bold">Admin: Simulate a Login</div>
                    
                    <Form
                        form={loginForm}
                        layout="vertical"
                        className="w-full"
                        onFinish={onLogin}
                    >
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                            {
                                required: true,
                                message: "Your email can't be empty!",
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                            {
                                required: true,
                                message: "Your password can't be empty!",
                            },
                            ]}
                        >
                            <Input.Password name="password"/>
                        </Form.Item>
                        
                        <Button 
                            htmlType='submit'
                            type="primary"
                            className="w-full text-center"
                            style={{ background: '#9C1FFF'}}
                        >
                            Log In
                        </Button>
                    </Form>
                </Card>
                : <ReactLoading
                    type={"spin"}
                    color={"purple"}
                    height={30}
                    width={30}
                    />
                }
        </div>
    )
}
 
export default SimulatedLogin