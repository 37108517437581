import react from 'react';
import { Card } from 'antd';

const AccessDenied = () => {
    return (
        <div className="flex w-full h-screen justify-center items-center">
            <Card className="w-1/2">
                Access Denied. Insufficient Permissions.
            </Card>
        </div>
    )
}

export default AccessDenied