import SherpaClient from "./main";

export const submitSurvey = async (survey_data) => {
  const response = await SherpaClient.post("/survey", survey_data);
  return response.data;
};

export const getConversationCount = async () => {
  const response = await SherpaClient.get("/survey/conversations/count");
  return response.data;
};

export const getAssignmentCount = async () => {
  const response = await SherpaClient.get("/survey/assignments/count");
  return response.data;
};
