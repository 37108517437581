
import React from 'react';
import { updateConversation } from '../api/conversation';
import { Button, Form, Input } from "antd";

const Setup = ({
  conversation,
  setConversation,
  assignment
}) => {
  const [detailsForm] = Form.useForm();

  const submitHandler = async ()  => {
    const formValues = detailsForm.getFieldsValue(true);

    if (formValues.essay) {

      // Update conversation in the mongo db + the local state? 
      await updateConversation(conversation.id, {
        "student_work": formValues.essay,
      })

      // Update local state
      setConversation({
        ...conversation, 
        "student_work": formValues.essay,
      })
    }
  }
  
  return (
    <>
        <div className="flex justify-center items-center flex-col w-full h-full flex">
            <div className="text-left flex flex-col w-full">
                <div className="px-8 text-md text-xl font-bold">Task</div>
                <div className="px-8 text-sm">{assignment.task_prompt}</div>
            </div>
            
            <div className="flex flex-col gap-4 items-center justify-center mt-8 w-full">
              <Form
                form={detailsForm}
                layout="vertical"
                className="w-full h-full px-8"
              >
                <Form.Item
                  label="Paste your submission below."
                  name="essay"
                  rules={[
                    {
                      required: true,
                      message: "Your essay can't be empty!",
                    },
                  ]}
                >
                  <Input.TextArea rows={8} maxLength={25000} style={{ resize: 'none' }}/>
                </Form.Item>
                <div className="w-full flex justify-end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={()=>{
                      submitHandler()
                    }}
                    style={{ background: '#9C1FFF'}}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            </div>
        </div>
    </>
  );
}

export default Setup



