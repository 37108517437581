import React, {useEffect, useState, useContext} from 'react';
import { getAllConversations } from '../api/conversation';
import ReactLoading from "react-loading";
import { Table, Statistic, Tag, Button } from 'antd'
import { convertTimeToFormat, convertTimeToTimeAgo, convertUtcToNow } from '../utils/general'
import { SherpaContext } from '../utils/sherpaContext';
import { CgTranscript } from 'react-icons/cg'
import { BsFillPlayFill } from 'react-icons/bs';


const StudentHome = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [conversations, setConversations] = useState(null);
  const [totalConversations, setTotalConversations] = useState(null);

  const queryParams = new URLSearchParams(props.location.search);

  const { userToken } = useContext(SherpaContext)

  const getCurrentPage = () => {
    return queryParams.get('page') ? parseInt(queryParams.get('page')) : 1
  }

  
  const COLUMNS = [
{
    title: 'Assignment',
    dataIndex: 'assignment',
    key: 'assignment',
    width: '20%',
    render: (assignment) => {
        return `${assignment.title.substring(0, 100)}${assignment.title.length > 100 ? "..." : ""}`
    }
    },
  {
    title: 'Status',
    dataIndex: 'evals',
    width: '1%',
    // sorter: (a, b) => a.evals.items.filter(item => item.status === "warning").length - b.evals.items.filter(item => item.status === "warning").length,
    render: (evaluations, record) => {
      let showFeedback = record.assignment.student_ai_feedback
      if (record.show_ai_feedback != null){
        showFeedback = record.show_ai_feedback
      }
      return showFeedback && evaluations.items.filter(item => item.status === "warning").length > 0 ? 
        <Tag color="#fca24e" className="status-tag">{evaluations.items.filter(item => item.status === "warning").length} {evaluations.items.filter(item => item.status === "warning").length > 1 ? "improvements" : "improvement"}</Tag> 
      : evaluations.is_complete ? 
        <Tag color="lime" className="status-tag">Completed</Tag>
      : <Tag color="orange" className="status-tag">In Progress</Tag>

    }
  },
  {
    title: 'Completed',
    dataIndex: 'completed_time',
    key: 'completed_time',
    width: '5%',
    render: (timestamp) => {
      if (timestamp) {
        const now = convertUtcToNow(timestamp)
        return convertTimeToTimeAgo(now)
      } else {
        return 'N/A'
      }
    }
  },
  // {
  //   title: 'Submitted',
  //   dataIndex: 'completed_time',
  //   key: 'completed_time',
  //   width: '5%',
  //   render: (timestamp) => {
  //     const now = convertUtcToNow(timestamp)
  //     return convertTimeToFormat(now) ?? ''
  //   }
  // },
  {
    title: 'Action',
    dataIndex: 'evals',
    key: 'action',
    width: '5%',
    render: (evaluations, record) => {
      let showFeedback = record.assignment.student_ai_feedback
      if (record.show_ai_feedback != null){
        showFeedback = record.show_ai_feedback
      }

      return evaluations.items.length > 0 && evaluations.is_complete && showFeedback ? 
        <>
            <Button type="primary" onClick={()=>{
              props.history.push('/student/evaluate/' + record._id)
            }}>
              See Feedback 
            </Button>
            {/* <Button icon={<CgTranscript className='absolute top-2'/>} onClick={()=>{
              props.history.push('/student/evaluate/' + record._id)
            }}>
              <span className="ml-3">See Transcript</span>
            </Button> */}
        </>
      : !evaluations.is_complete ? 
          <Button
            icon={<BsFillPlayFill size={20} className="absolute top-1" />}
            onClick={()=>{
              props.history.push('/student/conversation/' + record._id)
            }}
            type='primary'
          >
          <span className="ml-4">Resume</span>
        </Button>
      : 
      <Button icon={<CgTranscript className='absolute top-2'/>} onClick={()=>{
        props.history.push('/student/evaluate/' + record._id)
      }}>
        <span className="ml-3">See Transcript</span>
      </Button>
    }
  }
  
]

  const fetchData = async () => {
    setIsLoading(true)
    const conversationsData = await getAllConversations([], getCurrentPage(), false, true, true, true, false)
    setConversations(conversationsData.conversations)
    setTotalConversations(conversationsData.count)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData();
  }, [queryParams.get("page")]);


  return (
     <>
      <div className="bg-white w-full flex justify-between items-center" style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
        <div className="text-md m-4 px-4" style={{
          fontWeight: 600
        }}>{userToken.details.name.split(" ")[0]}'s Dashboard</div>
        <div className="w-32"></div>
      </div>
      <div className="w-full flex justify-center">
      {!isLoading ? 
      <div className="flex flex-col gap-8 m-8 w-full">
        <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
          <div className="p-8 flex justify-between items-center">
              <Statistic title="Number of Conversations" value={totalConversations?.[0]?.total_count} />
          </div>
        </div>
        <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
          <Table style={{
            fontSize: '20px !important'
          }} 
          rowClassName={() => 'clickable-row'}
          columns={COLUMNS} dataSource={conversations} onChange={(pagination, filters, sorter, extra) => {
            // update assignment ids
            console.log(filters)
            const assignment_ids = filters.assignment;
            if (assignment_ids) {
              const assignmentsString = assignment_ids.join(',');
              queryParams.set('assignments', assignmentsString);
              props.history.push({search: queryParams.toString()});
            } else {
              queryParams.delete('assignments')
              props.history.push({search: queryParams.toString()});
            }

            // pagination
            if (pagination?.current){
              queryParams.set('page', pagination?.current);
              props.history.push({search: queryParams.toString()});
            }

          }} 
          pagination={{ position: ['topRight'],current: getCurrentPage(), pageSize: 20, total: totalConversations?.[0]?.total_count, showSizeChanger: false }} />
        </div>
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default StudentHome;

