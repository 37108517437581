import SherpaClient from "./main";
import axios from "axios";

export const uploadToS3 = async (fileName, fileContents) => {
  try {
    const presignedPostUrl = await getPresignedPostUrl(fileName);

    const formData = new FormData();

    Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", fileContents); // The file has be the last element

    await axios.post(presignedPostUrl.url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    // get presigned public link
    const response = await SherpaClient.get(
      `/storage/presignedurl/${fileName}`
    );
    const presigned_url = response.data.presigned_url;

    return presigned_url;
  } catch (error) {
    console.error(error);
  }
};

export const getPresignedUrl = async (fileName) => {
  const response = await SherpaClient.get(`/storage/presignedurl/${fileName}`);
  const presigned_url = response.data.presigned_url;

  return presigned_url;
};
export const getPresignedPostUrl = async (fileName) => {
  const response = await SherpaClient.get(`/storage/presignedpost/${fileName}`);
  return response.data.presigned_post;
};
