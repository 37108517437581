import React, {useEffect, useState} from 'react';
import ReactLoading from "react-loading";
import { Select, Tag, Table, Statistic } from 'antd'
import { getEvaluations } from '../api/admin';
import InfoModal from '../components/InfoModal'
import { convertUtcToNow, convertTimeToTimeAgo } from '../utils/general';

const EvaluationDashboard = (props) => {
  const [infoModal, setInfoModal] = useState(false)
  const [currentEvaluation, setCurrentEvaluation] = useState()
  const COLUMNS = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (user) => {
      return user?.name
    }
  },
  {
    title: 'Email',
    dataIndex: 'user',
    key: 'user',
    render: (user) => {
      return user?.email
    }
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at) => {
      created_at = convertUtcToNow(created_at);
      return convertTimeToTimeAgo(created_at)
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      return status === "warning" ? <Tag color="red">Warning</Tag> : status === "verified" ? <Tag color="green">Verified</Tag> : null
    }
  },
  {
    title: 'Feedback',
    dataIndex: 'feedback',
    key: 'feedback',
    render: (feedback) => {
      return feedback?.type
    }
  },
  {
    title: 'Why?',
    dataIndex: 'feedback',
    key: 'feedback',
    render: (feedback) => {
      return feedback?.reason
    }
  },
]
  
  const [evaluations, setEvaluations] = useState(null);

  useEffect(() => {
    const fetchEvaluations = async () => {
        getEvaluations(props.weeksAgo)
            .then(response => {
              console.log(response)
              setEvaluations(response);
            })
            .catch(error => {
                console.error('Error fetching evaluations:', error);
            });
    }
    if (props.weeksAgo) {
      fetchEvaluations();  
    }
    
  }, [props.weeksAgo]);
  return (
     <>
      {currentEvaluation ? 
    <InfoModal 
        isOpen={infoModal}
        setIsOpen={setInfoModal}
        title="Evaluation Info"
        main={
            <div>
                <h2>Feedback</h2>
                <p><b>Feedback Type:</b> {currentEvaluation?.feedback?.type}</p>
                <p><b>Feedback Reason:</b> {currentEvaluation?.feedback?.reason}</p>

                <h2>Main</h2>
                <p><b>Status:</b> {currentEvaluation?.status}</p>
                <p><b>Question:</b> {currentEvaluation?.video.question}</p>
                <p><b>Student Response:</b> {currentEvaluation?.video.improved_response}</p>
                <p><b>AI Reason:</b> {currentEvaluation?.reason}</p>

                <h2>Sherpa Details</h2>
                <p><b>Confidence Score:</b> {currentEvaluation?.confidence_score}</p>
                <p><b>Confidence Reason:</b> {currentEvaluation?.confidence_reason}</p>
                <p><b>Action:</b> {currentEvaluation?.action}</p>
                <p><b>Model Answer:</b> {currentEvaluation?.model_answer.model_answer}</p>
            </div>
        }
    />: null}

      <div className="w-full flex flex-col justify-center">
      {evaluations ? 
      <div className="m-8 flex flex-col gap-8">
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <div className="p-8 flex justify-between items-center">
            <Statistic title="Number of Evaluations" value={evaluations.length} />
        </div>
      </div>
      <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}} >
        <Table pagination={{ pageSize: 100 }} columns={COLUMNS} dataSource={evaluations} onRow={(record)=>{
            return {
                onClick: (e) => {
                  setCurrentEvaluation(record)
                  setInfoModal(true)
                }
            }
        }} />
      </div>
      </div>
      : 
      <div className="flex justify-center items-center w-full h-screen">
         <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
      </div>
    }
    </div>
    </>
  ) 
}

export default EvaluationDashboard;

