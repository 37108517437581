import { useContext, useEffect, useState } from 'react';
import { auth } from '../utils/firebase';
import { BsFillLightningChargeFill, BsFillFileCheckFill } from "react-icons/bs"
import { PiChatsFill } from "react-icons/pi"
import { FaRocket } from "react-icons/fa"
import { SherpaContext } from '../utils/sherpaContext';
import { Button, Dropdown } from 'antd';
import ReactLoading from 'react-loading'
import SherpaLabs from '../img/logo_black_text.svg'
import BugReportModal from './BugReportModal';
import { useParams } from 'react-router-dom';
import { AiOutlineSetting } from 'react-icons/ai';
import InfoModal from './InfoModal';
import { getAssignmentCount, getConversationCount } from '../api/survey';

const EMAIL_TYPES = ["choate.edu"]
// "imua.ksbe.edu", "liceocampoverde.edu.ec", "txvirtual.org", "mosstudents.org", "aischennai.org", "tc.columbia.edu", "enishi.ac.jp", "wis.edu.hk"

const MenuItem = ({
  children,
  selected,
  icon: Icon,
  onClick,
  id
}) => {
  return (
    <div id={id} data-testid={id} onClick={onClick} className={`cursor-pointer flex justify-start gap-3 items-center text-md rounded-md p-3 ${selected ? `bg-[#F1EAFF]` : 'hover:bg-[#EDF2F7] transition duration-500 ease-in-out'}`}>

      <Icon color={selected ? "#652CE6" : "gray"} /><span>{children}</span>
    </div>
  )
}
const Authenticated = ({ children, history, showSidebar=true, forOnly=null, showHome=false, forAdminOnly=false, mobileOnly=true }) => {
  
  const { userToken, ltiToken, updateIntroStatus } = useContext(SherpaContext)
  const { assignmentid } = useParams();
  const [bugModal, setBugModal] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [newFeaturesModal, setNewFeaturesModal] = useState(false)
  // const [showSidebar_, setShowSidebar] = useState(showSidebar)

  useEffect(() => {

    const init = async () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true)
      }

      if (!ltiToken) {
        // Not Logged In
        if (!userToken) {
          history.push('/signup' + (assignmentid ? `?assignmentid=${assignmentid}` : ''))
        } else {
            // Email is not verifed
            // if (!userToken.user.emailVerified) {
            //   history.push('/notVerified')
            // }
            // if (!userToken.details.role){
            //   history.push('/onboarding')
            // }
            // const count = await getConversationCount()
            // const assignment_count = await getAssignmentCount()
            // if ((count.count > 0 && EMAIL_TYPES.some(type => userToken['user']['email'].includes(type)) && !userToken.details.intro_status.completed_survey && userToken.details.role === "student") || (userToken.details.role === "teacher" && !userToken.details.intro_status.completed_survey && assignment_count.count >= 2)) {
            //   if (!userToken['details']['completed_survey']) {
            //     history.push('/survey')
            //   }
            // }

            if (forOnly != null && !forOnly.includes(userToken.details.role)){
              history.push('/accessdenied')
            }

            if (!userToken.details.admin && forAdminOnly) {
              history.push('/accessdenied')
            }
          }
      }    
    } 

    init()
  }, []);


  return isMobile && mobileOnly ?
    <div className="w-full h-screen flex justify-center items-center">
      <p className="px-18 text-center">
        You can't use Sherpa on a mobile device! <br/><br/>Try again on a larger screen.
      </p>
    </div>
  : userToken ? 
  
  <div className="flex flex-row min-h-screen">
    <BugReportModal isOpen={bugModal} source="Chat" setIsOpen={setBugModal} />
    <InfoModal isOpen={newFeaturesModal} setIsOpen={setNewFeaturesModal} title="New Features for Sherpa!" main={
      <>
        <h2>We just launched our biggest update yet.</h2>
        <p>
          🎙 <b>Feedback Sharing</b> with students directly <br/>
          👨🏽‍🎓 <b>Student Dashboard</b> for students to review their conversations and AI feedback<br/>
          📊 <b>Formative and 💯 Summative</b> assignments <br/>
          🔖 <b>Transcript Summaries</b> to take away key insights<br/>
          📙 <b>PDF Upload</b> to create Reading Responses instantly<br/>
          🎗 A <a href="https://www.facebook.com/groups/249497677902116">wonderful community</a> of teachers we learn from every day<br/><br/>

          Watch the video to learn more!
        </p>
        <iframe className="w-full h-64 mt-4" src="https://www.youtube.com/embed/H9MEu2BbX9I?si=jt4vEn0JmXuVOMw-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </>
    }/>
    {showSidebar && !ltiToken ? 
    <div className="fixed w-52 bg-white min-h-screen px-8" style={{
      borderRight: '1.5px solid #E2E8F0',
      fontWeight: '600'
    }}>
      <div className="pt-8 cursor-pointer" onClick={()=>{
        history.push('/teacher/create')
      }}>
        <img src={SherpaLabs} className="w-32 h-8" id="logo" />
      </div>

      <div className="flex flex-col gap-4 mt-12">
         <MenuItem selected={history.location.pathname === '/teacher/gettingstarted'} icon={FaRocket} onClick={()=>{
            history.push('/teacher/gettingstarted')
        }} id="getting_started_menu_item">
          Get Started
        </MenuItem>
        <MenuItem selected={history.location.pathname === '/teacher/create'} icon={BsFillLightningChargeFill} onClick={()=>{
            history.push('/teacher/create')
        }} id="create_menu_item">
          Create
        </MenuItem>
        <MenuItem selected={history.location.pathname === '/teacher' || history.location.pathname.startsWith('/teacher/assignment')} icon={BsFillFileCheckFill} onClick={()=> {
            history.push('/teacher')
        }} id="assign_menu_item">
          Assignments
        </MenuItem>
        <MenuItem selected={history.location.pathname === '/teacher/submissions'  || history.location.pathname.startsWith('/teacher/conversation')} icon={PiChatsFill} onClick={()=> {
            if (history.location.pathname !== '/teacher/submissions') {
                history.push('/teacher/submissions')
            }
        }} id="submissions_menu_item">
          Submissions
        </MenuItem>

        { userToken.details.admin ? 
        <MenuItem selected={history.location.pathname.startsWith('/admin')} icon={AiOutlineSetting} id="evaluate_menu_item" onClick={()=>{
            history.push('/admin/teachers')
        }}>
          Admin
        </MenuItem>
        : null}
        <Button id="report-bug-button" type="dashed" onClick={()=>{
          setBugModal(true)
        }}>
          Report Bug
        </Button>
        {/* { userToken.details.admin ? 
        <Button id="toggle-sidebar-button" type="dashed" onClick={()=>{
          setShowSidebar(!showSidebar_)
        }}>
          Hide Sidebar
        </Button>
        : null} */}
        {/* <Button id="report-bug-button" type="primary" onClick={()=>{
          setNewFeaturesModal(true)
        }}>
          See New Features! 
        </Button> */}


      </div>
    </div>
    : null}
    <div className="flex flex-col min-h-screen w-full" style={{
        marginLeft: showSidebar && !ltiToken ? '273.5px' : '0'
    }}> 
      {!ltiToken ? 
      <div className={`w-full bg-white flex ${!showHome ? 'justify-end' : 'justify-between'}`} style={{
        borderBottom: '1.5px solid #E2E8F0'
      }}>
        {/* {!showSidebar_ ? 
          <div className="mx-4 my-2">
            <Button onClick={()=>{
              setShowSidebar(!showSidebar_)
            }}>
                  Show Sidebar
              </Button>
          </div>
        : null} */}
        {showHome ? 
          <div className="mx-4 my-2">
            <Button onClick={()=>{
                 if (userToken && userToken?.details?.role) {
                  if (userToken.details.role === "student"){
                      history.push('/student')
                  } else if (userToken.details.role === "teacher") {
                      history.push('/teacher/gettingstarted')
                  }
                } 
            }}>
                  Home
              </Button>
          </div>
        : null}
        <div className="mx-4 my-2">
          {userToken ? 
            <Dropdown menu={ {
              items: [
                {
                  key: '1',
                  label: (
                    <a target="_blank" rel="noopener noreferrer" onClick={async ()=>{
                      history.push('/student')
                  }}>
                      My Conversations
                    </a>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <a target="_blank" rel="noopener noreferrer" onClick={async ()=>{
                        await auth.signOut();
                        history.push('/login')
                    }}>
                      Log Out
                    </a>
                  ),
                },
              ]
            } } placement="bottomRight" arrow>
              <Button>{userToken.details.name}</Button>
            </Dropdown>
              // <Button onClick={async ()=>{
              
              // }}>
                   
              // </Button>
          : 
              <Button
                  onClick={()=>{
                      history.push('/login')
                  }}
              >
                  Log In
              </Button>
          }
        </div>
      </div>
      : null}
      <div className="bg-white h-full relative">{children}</div> 
    </div>
  </div> :  
  <div className="justify-center items-center flex w-full h-screen">
      <ReactLoading
      type={"spin"}
      color={"purple"}
      height={30}
      width={30}
    />
    </div>
};

export default Authenticated