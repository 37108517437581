import React, {useEffect, useState} from 'react';
import { Select } from 'antd'

const AdminMain = ({
  type,
  history,
  children
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [weeksAgo, setWeeksAgo] = useState(parseInt(urlParams.get('weeks_ago')) ?? 1) 

  useEffect(()=>{
    if (!weeksAgo) {
     urlParams.set('weeks_ago', '1');
     setWeeksAgo(1)
     window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
   } 
 }, [])

 return (
  <>
    <div className="bg-white w-full flex justify-between items-center" style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
        <div className="text-md m-4" style={{
          fontWeight: 600
        }}>{type.charAt(0).toUpperCase() + type.slice(1)}</div>
        <div>
        <Select
            className="w-48 mr-4"
            value={weeksAgo}
            onChange={(weeks_ago) => {
              setWeeksAgo(weeks_ago)
              urlParams.set('weeks_ago', weeks_ago.toString());
              window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
            }}              
          >
            <Select.Option value={1}>
              This Week
            </Select.Option>
            <Select.Option value={2}>
              1 Week Ago
            </Select.Option>
            <Select.Option value={3}>
              2 Weeks Ago
            </Select.Option>
            <Select.Option value={4}>
              3 Weeks Ago
            </Select.Option>
            <Select.Option value={5}>
              4 Weeks Ago
            </Select.Option>
            <Select.Option value={6}>
              5 Weeks Ago
            </Select.Option>
            <Select.Option value={7}>
              6 Weeks Ago
            </Select.Option>
            <Select.Option value={8}>
              7 Weeks Ago
            </Select.Option>
            <Select.Option value={9}>
              8 Weeks Ago
            </Select.Option>
            <Select.Option value={10}>
              9 Weeks Ago
            </Select.Option>
            <Select.Option value={11}>
              10 Weeks Ago
            </Select.Option>
            <Select.Option value={12}>
              11 Weeks Ago
            </Select.Option>
            <Select.Option value={13}>
              12 Weeks Ago
            </Select.Option>
          </Select>
        <Select
            className="w-48 mr-4"
            value={type}
            onChange={(id) => {
              history.push('/admin/' + id)
            }}              
          >
            <Select.Option value={'teachers'}>
              Teachers
            </Select.Option>
            <Select.Option value={'assignments'}>
              Assignments
            </Select.Option>
            <Select.Option value={'students'}>
              Students
            </Select.Option>
            <Select.Option value={'conversations'}>
              Conversations
            </Select.Option>
            <Select.Option value={'evaluations'}>
              Evaluations
            </Select.Option>
            <Select.Option value={'bugreports'}>
              Bug Reports
            </Select.Option>
          </Select>
        </div>
      </div>
      <div className="w-full flex flex-col justify-center">
        {React.cloneElement(children, { weeksAgo: weeksAgo, history: history })}
      </div>
    </>
 )
}

export default AdminMain