import React from "react"
import { Modal} from "antd"


const InfoModal = ({isOpen, setIsOpen, title, main}) => {

    return (
        <Modal style={{
            zIndex: 999999999999999999999,
        }} title={title} open={isOpen} footer={[
        ]} onCancel={() => {
          setIsOpen(false)
        }}>
           
            {main}
        </Modal>
    )
}

export default InfoModal