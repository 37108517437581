// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
const ENV = process.env.REACT_APP_ENV

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {}

if (ENV === "PROD") {
  firebaseConfig = {
    apiKey: "AIzaSyDIy8O0d4py23u3Oh2GRWNDk4Y4acHZz18",
    authDomain: "sherpa-prod-30bc4.firebaseapp.com",
    projectId: "sherpa-prod-30bc4",
    storageBucket: "sherpa-prod-30bc4.appspot.com",
    messagingSenderId: "388433561015",
    appId: "1:388433561015:web:f615489b3207a4c5619432",
    measurementId: "G-K7N2GEC4L3"
  };
} else if (ENV === "DEV") {
  firebaseConfig = {
    apiKey: "AIzaSyBXR9UoK-UZRqJMt4fisjDPIZ3l7KFN0-E",
    authDomain: "sherpa-speak-on-it.firebaseapp.com",
    projectId: "sherpa-speak-on-it",
    storageBucket: "sherpa-speak-on-it.appspot.com",
    messagingSenderId: "185213209690",
    appId: "1:185213209690:web:a9a27293ed4666919d1c63",
    measurementId: "G-R1CMDNGKM3"
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app)
