import React, {useContext, useEffect, useState} from 'react';
import { getAssignment } from '../api/assignment'
import { getStudentsWithConversations, getAllStudentConversationsForAssignment } from '../api/dashboard';
import { useParams } from "react-router-dom";
import { SherpaContext } from '../utils/sherpaContext';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { List, Card, Button } from 'antd';
import ReactLoading from 'react-loading'

const LTI_SAMPLE_ASSIGNMENT_ID = "64cc9c7439ddbd875106a28d"

const TeacherAssignmentEvalution = (props) => {
  const [assignment, setAssignment] = useState(null)

  const { assignmentid } = useParams();
  const { ltiToken } = useContext(SherpaContext)

  useEffect(()=>{
    const fetchAssignment = async () => {
      const data = await getAssignment(assignmentid)
      setAssignment(data)
    }
    if (assignmentid) {
      fetchAssignment()
    }
    
  }, [assignmentid])

  const [students, setStudents] = useState(null);
  useEffect(() => {
    if (assignment) {
      getStudentsWithConversations(assignmentid)
        .then(response => {
          setStudents(response);
        })
        .catch(error => {
          console.error('Error fetching students:', error);
        });
    }
  }, [assignment]);
  


  return assignment && students ? (
    <>
     <div className={`bg-white w-full flex justify-between items-center h-16`} style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
        <div className="flex items-center cursor-pointer pl-4 gap-2" onClick={()=>{
            props.history.goBack();
          }}>
            <AiOutlineLeft />
            Back
          </div>
        {assignment ? 
        <>
          <div className="text-md m-4" style={{
            fontWeight: 600
          }}>{ltiToken ? "Student Submissions" : assignment.title}</div>
          </> : 
        null}
        <div className="w-24"></div>
    </div>
    <div className="w-full flex py-16 justify-center flex-col items-center gap-8">
      {ltiToken && assignmentid === LTI_SAMPLE_ASSIGNMENT_ID ? 
      <div className="rounded-lg bg-[#ffe6e8] w-[800px]">
        <div className="px-8 py-6 flex items-center justify-between">
          <div className="text-red-800 flex flex-col">
            <span className="text-lg" style={{fontWeight: '600'}}>3 warnings identified</span>
            <span className="text-sm opacity-80">Warnings are moments that could indicate an area of concern.</span>
          </div>
          <Button icon={<AiOutlineRight size={28}/>} style={{border: '0', background: '#ffe6e8'}} className="text-red-800 opacity-50" onClick={()=>{
            props.history.push('/teacher/evaluate/' + assignmentid)
          }}></Button>
        </div>
      </div>
      : null}

      <div className="w-[800px]">
        {students.length > 0 ?
        <List
            style={{border: '1.5px solid #E2E8F0'}}
            size="large"
            bordered
            dataSource={ltiToken && assignmentid === LTI_SAMPLE_ASSIGNMENT_ID ? [...students, {
              "id": "test",
              "name": "John Lee"
            },
            {
              "id": "random_id_1",
              "name": "Alice Johnson"
            },
            {
              "id": "random_id_2",
              "name": "Bob Smith"
            },
            {
              "id": "random_id_3",
              "name": "Charlie Brown"
            },
            ] : students}
            renderItem={(student) => <List.Item className="hover:bg-[#f7f7f7] w-full cursor-pointer"
                  onClick={async ()=>{
                      const conversations = await getAllStudentConversationsForAssignment(student.id, assignmentid);
                      props.history.push('/teacher/conversation/' + conversations[conversations.length - 1].id)
                  }}>
              <div className="w-full flex justify-between items-center">
                  <div className="text-md font-semibold">{student?.name}</div>

                  {ltiToken && assignmentid === LTI_SAMPLE_ASSIGNMENT_ID && (student.name === "Joseph Tey" || student.name === "John Lee") ?  
                    <div className="text-xs text-red-800 font-semibold py-2 px-4 bg-[#ffe6e8] rounded-lg">{student.name === "Joseph Tey" ? "2 warnings" : student.name === "John Lee" ? "1 warning" : null}</div>
                  : null}
              </div>
            </List.Item>}
          />
          : 
          <Card
            style={{border: '1.5px solid #E2E8F0'}}
          >
            No students have spoken about this!
          </Card>
          }
            
      </div>
      </div>
  </>
  ) : 
  <div className="w-full h-full flex items-center justify-center">
    <ReactLoading
        type={"spin"}
        color={"purple"}
        height={30}
        width={30}
      />
  </div>
}

export default TeacherAssignmentEvalution;
